import React from 'react';

// import cx from 'classnames';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';

// custom components
import { ReactTableMUIView } from 'components';

// react-table hooks
import { useSortBy, useTable } from 'react-table';

// intl lang
import { useIntl } from 'react-intl';

// CSS
const useStyles = makeStyles((theme) => ({
	actionsWrapper: {
		display: 'flex',
		gap: theme.spacing(0.5),
		flexWrap: 'wrap',
		maxWidth: 160,
		justifyContent: 'center',
	},
	actionIconButton: {
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
}));

function MediafileTableView({
	mediafiles,
	columnsConfigure,
	// handleGotoDetail,
	// handleShowLargePreview,
	// handlePatchMediafile,
	appliedSortBy,
	handleSortBy,
	setRTableInst,
	mediafileActions,
	noDataMsg,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();

	// ##############################
	// React-Table (treat it as part of local states)
	// #############################
	// function to select rows that require highlight
	const rowHighlightPicker = (row) => row.isSelected;

	const tableInst = useTable(
		{
			columns: columnsConfigure,
			data: mediafiles,
			initialState: { sortBy: appliedSortBy },
			manualSortBy: true,
			disableMultiSort: true,
			autoResetSortBy: false,
			getRowId: (rowData) => rowData.id,
			mediafileActions,
		},
		useSortBy,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				let actionsCol = {
					id: 'actions',
					disableSortBy: true,
					Header: intl.formatMessage({
						id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderActions',
					}),
					Cell: function rowAction({ row, mediafileActions }) {
						let mediafile = row.original;
						return (
							<div className={classes.actionsWrapper} aria-label="table-row-actions">
								{/* {mediafileActions.map((action, idx) => {
									return action.shouldDisplay(mediafile) ? (
										<IconButton
											key={mediafile.id + '' + idx}
											className={classes.actionIconButton}
											size="small"
											// color="primary"
											title={action.tooltip}
											onClick={(e) =>
												typeof action.clickHandler === 'function'
													? action.clickHandler(mediafile, e)
													: null
											}
										>
											{action.icon}
										</IconButton>
									) : null;
								})} */}
								{mediafileActions.map((action, idx) => {
									return action.shouldDisplay(mediafile) ? (
										action.CustomComp ? (
											<action.CustomComp key={mediafile.id + '' + idx} mediafile={mediafile} />
										) : (
											<IconButton
												key={mediafile.id + '' + idx}
												className={classes.actionIconButton}
												size="small"
												// color="primary"
												title={action.tooltip}
												onClick={(e) =>
													typeof action.clickHandler === 'function'
														? action.clickHandler(mediafile, e)
														: null
												}
											>
												{action.icon}
											</IconButton>
										)
									) : null;
								})}
							</div>
						);
					},
				};
				return [actionsCol, ...columns];
			});
		}
	);
	React.useEffect(() => {
		handleSortBy(tableInst.state.sortBy);
	}, [handleSortBy, tableInst.state.sortBy]);

	React.useEffect(() => {
		setRTableInst(tableInst);
	}, [setRTableInst, tableInst]);

	return (
		<ReactTableMUIView
			isStickyHeader={true}
			showBorder={true}
			// enableResizer={true}
			rowHighlightPicker={rowHighlightPicker}
			// table props
			getTableProps={tableInst.getTableProps}
			headerGroups={tableInst.headerGroups}
			prepareRow={tableInst.prepareRow}
			rows={tableInst.rows}
			noDataMsg={noDataMsg}
		/>
	);
}

MediafileTableView.propTypes = {
	/**
	 * Array of media file.
	 	[
			 {
				id: 'xxxxx',
				title: 'xxxx',
				previewUrl: 'https://xxx.xxx.com/xxxx',
			 }
		 ]
	 */
	mediafiles: PropTypes.array.isRequired,
	/**
	 * Array of table columns configure
	 */
	columnsConfigure: PropTypes.array.isRequired,
	/**
	 * sortBy array
	 * NB: it is used as initial state in rTable for sorting
	 */
	appliedSortBy: PropTypes.array.isRequired,
	/**
	 * handle sorting mediafile
	 * @param {array} sortBy_in_rTableInst. it is tableInst.state.sortBy
	 */
	handleSortBy: PropTypes.func.isRequired,
	/**
	 * set rTable instance
	 */
	setRTableInst: PropTypes.func.isRequired,
	// /**
	//  * An array of selected row Ids
	//  */
	// selectedRowIds: PropTypes.arrayOf(PropTypes.string),
	// /**
	//  * Handle selection of rows
	//  * @param {array} selectedRowIds. An array of selected row ids
	//  */
	// handleRowsSelection: PropTypes.func,
	/**
	 * Extra click actions to each media file
		[
			{
				shouldDisplay: (mediafile)=>true/false, // hook function to verify should the action be displayed for the mediafile
				icon: <AddIcon fontSize="small" />,
				tooltip: 'add',
				clickHandler: (mediafile, event) => {},
			},
			{
				shouldDisplay: (mediafile)=>true/false, // hook function to verify should the action be displayed for the mediafile
				icon: <SaveIcon fontSize="small" />,
				tooltip: 'Save',
				clickHandler: (mediafile, event) => {},
			},
		],
	*/
	mediafileActions: PropTypes.arrayOf(PropTypes.object),
	//  message when no mediafile
	noDataMsg: PropTypes.string,
};
MediafileTableView.defaultProps = { /* selectedRowIds: [], */ noDataMsg: 'No Mediafiles!!' };
export default MediafileTableView;
