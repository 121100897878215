// en Intl messages
// NOTE: If you dont know the translation, keep it in english but add a comment above with TODO
const locales = {
	// ##############################
	// global (general) message
	// #############################
	'GENERAL.Search': {
		en: 'Search',
		zh: '搜索',
	},
	'GENERAL.SearchPlaceholder': {
		en: 'Type to search...',
		zh: '搜索',
	},
	'GENERAL.Yes': {
		en: 'Yes',
		zh: '是',
	},
	'GENERAL.No': {
		en: 'No',
		zh: '否',
	},
	'GENERAL.Save': {
		en: 'Save',
		zh: '保存',
	},
	'GENERAL.SaveAs': {
		en: 'Save As',
		zh: '',
	},
	'GENERAL.Close': {
		en: 'Close',
		zh: '关闭',
	},
	'GENERAL.Confirm': {
		en: 'Confirm',
		zh: '确认',
	},
	'GENERAL.Cancel': {
		en: 'Cancel',
		zh: '取消',
	},
	'GENERAL.Preview': {
		en: 'Preview',
		zh: '预览',
	},
	'GENERAL.View': {
		en: 'View',
		zh: '查看',
	},
	'GENERAL.Edit': {
		en: 'Edit',
		zh: '编辑',
	},
	'GENERAL.Add': {
		en: 'Add',
		zh: '添加',
	},
	'GENERAL.Delete': {
		en: 'Delete',
		zh: '删除',
	},
	'GENERAL.Duplicate': {
		en: 'Duplicate',
		zh: '复制',
	},
	'GENERAL.InvalidUrl': {
		en: 'Invalid Url',
		zh: '失效链接',
	},
	'GENERAL.Forever': {
		en: 'Forever',
		zh: '永久',
	},
	'GENERAL.Now': {
		en: 'Now',
		zh: '现在',
	},
	'GENERAL.None': {
		en: 'None',
		zh: '无',
	},
	'GENERAL.Ok': {
		en: 'Ok',
		zh: '确认',
	},
	'GENERAL.N/A': {
		en: 'N/A',
		zh: '无',
	},
	'GENERAL.Rename': {
		en: 'Rename',
		zh: '重命名',
	},
	'GENERAL.Select': {
		en: 'Select',
		zh: '选择',
	},
	'GENERAL.Error': {
		en: 'Error',
		zh: '错误',
	},
	'GENERAL.Remove': {
		en: 'Remove',
		zh: '移除',
	},
	'GENERAL.Continue': {
		en: 'Continue',
		zh: '继续',
	},
	'GENERAL.Reset': {
		en: 'Reset',
		zh: '重置',
	},
	'GENERAL.Undefined': {
		en: 'Undefined',
		zh: '无定义',
	},
	'GENERAL.Print': {
		en: 'Print',
		zh: '打印',
	},
	'GENERAL.Open': {
		en: 'Open',
		zh: '打开',
	},
	'GENERAL.Clear': {
		en: 'Clear',
		zh: '清除',
	},
	'GENERAL.Batch': {
		en: 'Batch',
		zh: '',
	},
	'GENERAL.More': {
		en: 'More',
		zh: '更多',
	},
	'GENERAL.Setting': {
		en: 'Setting',
		zh: '设置',
	},
	'GENERAL.SortBy': {
		en: 'Sort By',
		zh: '',
	},
	'GENERAL.Actions': {
		en: 'Actions',
		zh: '',
	},
	'GENERAL.DescLong': {
		en: 'Z-A',
		zh: '',
	},
	'GENERAL.AscLong': {
		en: 'A-Z',
		zh: '',
	},
	'GENERAL.DescShort': {
		en: 'Z-A',
		zh: '',
	},
	'GENERAL.AscShort': {
		en: 'A-Z',
		zh: '',
	},
	'GENERAL.MilliSecond': {
		en: 'ms',
		zh: '毫秒',
	},
	'GENERAL.GreaterZeroErrorMsg': {
		en: `Value must be greater than 0`,
		zh: '',
	},
	'GENERAL.NotSupported': {
		en: `Not Supported`,
		zh: '',
	},
	'GENERAL.ValueLabel': {
		en: 'Value',
		zh: '',
	},
	'GENERAL.NotEmpty': {
		en: 'Can not be empty',
		zh: '',
	},
	// 'GENERAL.Active':{
	// 	en: 'Active',
	// 	zh: '正常',
	// },
	// ##############################
	// React-table-6 message
	// #############################
	'ReactTable.PreviousText': {
		en: 'Previous',
		zh: '前一页',
	},
	'ReactTable.NextText': {
		en: 'Next',
		zh: '后一页',
	},
	'ReactTable.LoadingText': {
		en: 'Loading...',
		zh: '加载中...',
	},
	'ReactTable.NoDataText': {
		en: 'No rows found',
		zh: '无数据',
	},
	'ReactTable.PageText': {
		en: 'Page',
		zh: '页',
	},
	'ReactTable.RowsText': {
		en: 'rows',
		zh: '行',
	},
	'ReactTable.OfText': {
		en: 'of',
		zh: '共',
	},
	// ##############################
	// Screen Manager Page
	// #############################
	// 'GENERAL.Screen.landscape':{
	// 	en: 'landscape',
	// 	zh: '横屏',
	// },
	// 'GENERAL.Screen.portrait':{
	// 	en: 'portrait',
	// 	zh: '竖屏',
	// },
	// screen routes
	'pages.ScreenManager.Routes.ScreenManager': {
		en: 'Screen Manager',
		zh: '屏幕管理',
	},
	'pages.ScreenManager.Routes.Screen': {
		en: 'Screens',
		zh: '屏幕',
	},
	'pages.ScreenManager.Routes.Playlists': {
		en: 'Playlists',
		zh: '播放列表',
	},
	'pages.ScreenManager.Routes.Departments': {
		en: 'Departments',
		zh: '部署位置',
	},
	'pages.ScreenManager.Routes.Schedules': {
		en: 'Bulk Scheduler',
		zh: '批量调度程序',
	},
	'pages.ScreenManager.Routes.Screenboard': {
		en: 'Screenboard',
		zh: '',
	},
	// screen manager
	'pages.ScreenManager.components.ScreenManager.ScreensNavName': {
		en: 'Screens',
		zh: '屏幕',
	},
	'pages.ScreenManager.components.ScreenManager.PlaylistsNavName': {
		en: 'Playlists',
		zh: '播放列表',
	},
	'pages.ScreenManager.components.ScreenManager.DepartmentsNavName': {
		en: 'Departments',
		zh: '部署位置',
	},
	'pages.ScreenManager.components.ScreenManager.SchedulesNavName': {
		en: 'Bulk Scheduler',
		zh: '批量调度程序',
	},
	'pages.ScreenManager.components.ScreenManager.ScreenboardNavName': {
		en: 'Screenboard',
		zh: '',
	},
	'pages.ScreenManager.components.ScreenManager.StartleLoginName': {
		en: 'In-Store Radio',
		zh: '',
	},
	// screens
	'pages.ScreenManager.components.Screens.TableTitle': {
		en: 'Manage Screen Players',
		zh: '管理屏幕',
	},
	'pages.ScreenManager.components.Screens.SiteCol': {
		en: 'Site',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.NameCol': {
		en: 'Name',
		zh: '名称',
	},
	'pages.ScreenManager.components.Screens.CodeCol': {
		en: 'Code',
		zh: '代码',
	},
	// TODO
	'pages.ScreenManager.components.Screens.OrientationCol': {
		en: 'Orientation (Portrait/Landscape)',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.CurrentContentCol': {
		en: 'Content',
		zh: '当前播放',
	},
	'pages.ScreenManager.components.Screens.CurrentStatusCol': {
		en: 'Player Status',
		zh: '播放状态',
	},
	// TODO
	'pages.ScreenManager.components.Screens.lastConnectedAtCol': {
		en: 'Last Seen',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.CurrentScheduleText': {
		en: 'Current Schedule: {scheduleText}',
		zh: '当前时间表: {scheduleText}',
	},
	'pages.ScreenManager.components.Screens.CurrentPlaylistText': {
		en: 'Current Playlist: {PLName}',
		zh: '当前播放列表: {PLName}',
	},
	'pages.ScreenManager.components.Screens.CurrentPlaylistLastUpdatedText': {
		en: 'Playlist Modified At: {updatedAt}',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.CurrentContentUnavailable': {
		en: 'Content Unavailable',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.ViewSchedulesLinkText': {
		en: 'View Schedules',
		zh: '查看时间表',
	},
	'pages.ScreenManager.components.Screens.NotifyErrorNoDataChange': {
		en: 'No data has been changed',
		zh: '没有信息更改，不需要保存',
	},
	'pages.ScreenManager.components.Screens.NotifyErrorMissingRequiredData': {
		en: 'Missing Data. Please enter the required screen details',
		zh: '请输入必需的屏幕信息',
	},
	// TODO
	'pages.ScreenManager.components.Screens.NotifyGenAddScreenSuccess': {
		en: 'New Screen Added Successfully!',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.AddScreenTooltipTitle': {
		en: 'Add New Screen Player',
		zh: '添加新屏幕',
	},
	'pages.ScreenManager.components.Screens.AddScreenDialogTitle': {
		en: 'Enter the details for the new Screen',
		zh: '输入新屏幕信息',
	},
	'pages.ScreenManager.components.Screens.AddScreenDialogScreenNameLabel': {
		en: 'Screen Name',
		zh: '屏幕名称',
	},
	'pages.ScreenManager.components.Screens.AddScreenDialogScreenCodeLabel': {
		en: 'Screen Code',
		zh: '屏幕代码',
	},
	'pages.ScreenManager.components.Screens.AddScreenDialogScreenOrientationLabel': {
		en: 'Orientation',
		zh: '屏幕方向',
	},
	'pages.ScreenManager.components.Screens.AddScreenDialogIsLinuxboxLabel': {
		en: 'Is LinuxBox?',
		zh: '',
	},
	// TODO
	'pages.ScreenManager.components.Screens.AddScreenDialogSetPLLabel': {
		en: 'Assign Playlist',
		zh: '',
	},
	// TODO
	'pages.ScreenManager.components.Screens.AddScreenDialogSetPLMenuItemNone': {
		en: 'None (I Will Assign Later)',
		zh: '',
	},
	// TODO
	'pages.ScreenManager.components.Screens.AddScreenDialogSetPLMenuItemNew': {
		en: 'Create A New Playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.AddScreenDialogNewPLLabel': {
		en: 'Playlist Name',
		zh: '名称',
	},
	// TODO
	'pages.ScreenManager.components.Screens.AddScreenDialogNewPLErrDup': {
		en: 'Playlist Already Exists!',
		zh: '',
	},
	// TODO
	'pages.ScreenManager.components.Screens.AddScreenDialogNewPLSetDeptLabel': {
		en: 'Assign Department To The Playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.DelScreenDialogTitle': {
		en: 'Delete Screen',
		zh: '删除屏幕',
	},
	'pages.ScreenManager.components.Screens.DelScreenDialogMessage': {
		en: 'Please confirm you want to delete the screen - {screenName}',
		zh: '请确认删除屏幕 - {screenName}',
	},
	'pages.ScreenManager.components.Screens.RestartPlayerDialogMessage': {
		en: 'Please confirm you want to restart the player - {playerName}',
		zh: '',
	},
	'pages.ScreenManager.components.Screens.RestartPlayerDialogTitle': {
		en: 'Restart Player',
		zh: '',
	},
	// screen playlists
	'pages.ScreenManager.components.Playlists.TableTitle': {
		en: 'Manage Playlists',
		zh: '管理播放列表',
	},
	'pages.ScreenManager.components.Playlists.TitleCol': {
		en: 'Title',
		zh: '名称',
	},
	'pages.ScreenManager.components.Playlists.NoOfItemsCol': {
		en: 'No. of Items',
		zh: '页面数量',
	},
	'pages.ScreenManager.components.Playlists.ViewNoOfItemText': {
		en: '{numberOfItems} Items',
		zh: '{numberOfItems}个页面',
	},
	// TODO
	'pages.ScreenManager.components.Playlists.DurationCol': {
		en: 'Duration',
		zh: '',
	},
	// TODO
	'pages.ScreenManager.components.Playlists.LayoutCol': {
		en: 'Orientation (Portrait/Landscape)',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncGroupName': {
		en: 'Group Name',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.DepartmentCol': {
		en: 'Department',
		zh: '部署位置',
	},
	'pages.ScreenManager.components.Playlists.IsMasterCol': {
		en: 'Is Master',
		zh: '是否主播放列表',
	},
	'pages.ScreenManager.components.Playlists.AddPlaylistTooltipTitle': {
		en: 'Add New Playlist',
		zh: '添加新播放列表',
	},
	'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLNameLabel': {
		en: 'Playlist Name',
		zh: '名称',
	},
	'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLDeptLabel': {
		en: 'Department',
		zh: '部署位置',
	},
	'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLDeptHelperText': {
		en: 'Please select the department',
		zh: '请选择部署位置',
	},
	// TODO
	'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLLayoutLabel': {
		en: 'Orientation',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLIsMasterLabel': {
		en: 'Is Master',
		zh: '是否主播放列表',
	},
	'pages.ScreenManager.components.Playlists.AddPlaylistDialogTitle': {
		en: 'Enter the details of the new Playlist',
		zh: '输入播放列表信息',
	},
	// TODO
	'pages.ScreenManager.components.Playlists.NotifyGenAddPLSuccess': {
		en: 'New Playlist Added Successfully!',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.NotifyErrorNoDataChange': {
		en: 'No data has been changed',
		zh: '没有信息更改，不需要保存',
	},
	'pages.ScreenManager.components.Playlists.NotifyErrorMissingRequiredData': {
		en: 'Missing Data. Please enter the required playlist details',
		zh: '请输入必需的列表信息',
	},
	'pages.ScreenManager.components.Playlists.DelPlaylistDialogTitle': {
		en: 'Delete Playlist',
		zh: '删除播放列表',
	},
	'pages.ScreenManager.components.Playlists.DelPlaylistDialogMessage': {
		en: 'Please confirm you want to delete the playlist - {PLName}',
		zh: '请确认删除播放列表 - {PLName}',
	},
	'pages.ScreenManager.components.Playlists.DuplicatePlaylistDialogTitle': {
		en: 'Duplicate Playlist',
		zh: '复制播放列表',
	},
	'pages.ScreenManager.components.Playlists.DuplicatePlaylistDialogMessage': {
		en: 'Please confirm you want to duplicate the playlist - {PLName}',
		zh: '请确认复制播放列表 - {PLName}',
	},
	// TODO
	'pages.ScreenManager.components.Playlists.previewDialogShowMasterSwitchLabel': {
		en: 'Master Slides',
		zh: '',
	},
	// TODO
	'pages.ScreenManager.components.Playlists.previewDialogShowMasterSwitchTooltip': {
		en: 'Show/Hide master slides (added by the admin) from the playlist preview',
		zh: '',
	},
	// screen departments
	'pages.ScreenManager.components.Departments.TableTitle': {
		en: 'Manage Departments',
		zh: '管理部署位置',
	},
	'pages.ScreenManager.components.Departments.NameCol': {
		en: 'Name',
		zh: '名称',
	},
	'pages.ScreenManager.components.Departments.MasterPLCol': {
		en: 'Master Playlists',
		zh: '主播放列表',
	},
	'pages.ScreenManager.components.Departments.CreatedByCol': {
		en: 'Created By',
		zh: '',
	},
	'pages.ScreenManager.components.Departments.NoMasterPLText': {
		en: 'No Master Playlist',
		zh: '无',
	},
	'pages.ScreenManager.components.Departments.NotifyErrorNoDataChange': {
		en: 'No data has been changed',
		zh: '没有信息更改，不需要保存',
	},
	'pages.ScreenManager.components.Departments.NotifyErrorMissingRequiredData': {
		en: 'Missing Data. Please enter the required department details',
		zh: '请输入必需的信息',
	},
	'pages.ScreenManager.components.Departments.AddDepartmentDialogNameLabel': {
		en: 'Department Name',
		zh: '部署位置名称',
	},
	'pages.ScreenManager.components.Departments.AddDepartmentDialogTitle': {
		en: 'Enter the details of the new department',
		zh: '输入部署位置信息',
	},
	'pages.ScreenManager.components.Departments.DelDepartmentDialogTitle': {
		en: 'Delete Department',
		zh: '删除部署位置',
	},
	'pages.ScreenManager.components.Departments.DelDepartmentDialogMessage': {
		en: 'Please confirm you want to delete the department - {deptName}',
		zh: '请确认删除部署位置 - {deptName}',
	},
	'pages.ScreenManager.components.Departments.AddDepartmentTooltipTitle': {
		en: 'Add New Department',
		zh: '添加新部署位置',
	},
	// screen schedules
	'pages.ScreenManager.components.Schedules.handleScheduleStartDTError': {
		en: 'Start datetime must be at least {minutes} minutes before the end datetime',
		zh: '开始时间必须至少是结束时间之前的{minutes}分钟',
	},
	'pages.ScreenManager.components.Schedules.handleScheduleStartDTWarning': {
		en: 'Start datetime is in the past. Make sure that is what you want...',
		zh: '开始时间是在过去,确定这是你想要的...',
	},
	'pages.ScreenManager.components.Schedules.handleScheduleEndDTError': {
		en: 'End datetime must be at least {minutes} minutes after the start datetime',
		zh: '结束时间必须至少是开始时间之后的{minutes}分钟',
	},
	'pages.ScreenManager.components.Schedules.handleScheduleEndDTWarning': {
		en: 'End datetime is in the past. Make sure that is what you want...',
		zh: '结束时间是在过去,确定这是你想要的...',
	},
	'pages.ScreenManager.components.Schedules.onPublishErrorWholeWeekCoverage': {
		en: 'Schedule must cover the whole week',
		zh: '时间表必须覆盖整个星期',
	},
	'pages.ScreenManager.components.Schedules.onPublishErrorAtLeastOneScreen': {
		en: 'At least one screen required',
		zh: '至少需要选择一个屏幕',
	},
	'pages.ScreenManager.components.Schedules.onPublishNotifyAddScheDone': {
		en: 'The schedule has been added to the screen',
		zh: '时间表已成功部署到屏幕',
	},
	'pages.ScreenManager.components.Schedules.onPublishNotifyEditScheDone': {
		en: 'The changes to the schedule has been saved',
		zh: '修改已经保存',
	},
	'pages.ScreenManager.components.Schedules.onPublishNotifyPublishScheDone': {
		en: 'The schedule has been added to selected screens',
		zh: '时间表已成功部署到选择的屏幕',
	},
	'pages.ScreenManager.components.Schedules.DelScheduleDialogTitle': {
		en: 'Delete Schedule',
		zh: '删除时间表',
	},
	'pages.ScreenManager.components.Schedules.DelScheduleDialogMessage': {
		en: 'Please confirm you want to delete the schedule: {schedule}',
		zh: '请确认删除时间表: {schedule}',
	},
	'pages.ScreenManager.components.Schedules.handleScheDelNotifyDelDone': {
		en: 'The schedule is deleted from the screen',
		zh: '时间表已成功删除',
	},
	'pages.ScreenManager.components.Schedules.PublishButtonText': {
		en: 'Publish to Screens',
		zh: '部署时间表',
	},
	'pages.ScreenManager.components.Schedules.PageTitle': {
		en: 'Manage Schedule for "{playerName}"',
		zh: '管理的时间表 {playerName}',
	},
	'pages.ScreenManager.components.Schedules.BulkSchedulerPageTitle': {
		en: 'Bulk Scheduler',
		zh: '批量调度程序',
	},
	'pages.ScreenManager.components.Schedules.ScheduleQueue.Title': {
		en: 'Schedule Queue',
		zh: '时间表队列',
	},
	'pages.ScreenManager.components.Schedules.ScheduleQueue.NoScheText': {
		en: 'No Schedule',
		zh: '没有时间表',
	},
	'pages.ScreenManager.components.Schedules.ScheduleQueue.AddScheFirstText': {
		en: 'Please Add Schedule First',
		zh: '请先添加时间表',
	},
	'pages.ScreenManager.components.Schedules.ScheduleQueue.AddScheButtonTooltip': {
		en: 'Add New Schedule',
		zh: '添加新时间表',
	},
	'pages.ScreenManager.components.Schedules.UserSelections.SelectScreenText': {
		en: 'Select Screens',
		zh: '选择屏幕',
	},
	'pages.ScreenManager.components.Schedules.UserSelections.SelectScreenHelperText': {
		en: 'Select At Least One Screen To Start Creating Schedule',
		zh: '至少选择一个屏幕来开始创建时间表',
	},
	'pages.ScreenManager.components.Schedules.UserSelections.ScreenOrientationText': {
		en: 'Screen Orientation',
		zh: '屏幕方向',
	},
	'pages.ScreenManager.components.Schedules.UserSelections.ScheStartDTText': {
		en: 'Schedule Start Datetime',
		zh: '时间表开始时间',
	},
	'pages.ScreenManager.components.Schedules.UserSelections.ScheEndDTText': {
		en: 'Schedule End Datetime',
		zh: '时间表结束时间',
	},
	'pages.ScreenManager.components.Schedules.WeeklyScheduler.SelectPLDialogTitle': {
		en: 'Select Playlist',
		zh: '选择播放列表',
	},
	// TODO
	'pages.ScreenManager.components.Schedules.WeeklyScheduler.SelectPLDialogDDTooltip': {
		en: 'Assign a playlist. Disabled ones are either orientation mismatch or is a master playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionChangeColor': {
		en: 'Change Color',
		zh: '更换颜色',
	},
	'pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionGoToPlaylist': {
		en: 'Go to Playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionDeleteTitle': {
		en: 'Delete Event',
		zh: '删除时间段',
	},
	'pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionDeleteMessage': {
		en: 'Are you sure you want to delete the event - {event}',
		zh: '请确认要删除的时间段 - {event}',
	},
	// TODO: zh
	'pages.ScreenManager.components.Schedules.PageTourStep1': {
		en:
			// eslint-disable-next-line no-multi-str
			'WEEKLY-REPEATING PLAYLIST SCHEDULER {br} \
			(This tour guide always awailable from here) {br}{br} \
			\
			This page lets you schedule the screen content based on the days of the week.\
			In addition, you can create more than 1 of these timetables for your need. Lets get started.',
	},
	// TODO: zh
	'pages.ScreenManager.components.Schedules.PageTourStep2': {
		en:
			// eslint-disable-next-line no-multi-str
			'SCHEDULE QUEUE {br}{br} \
			\
			A list of timetables applied to the screen. If you see none, click "+" button to add one.{br}{br} \
			\
			- A screen can have more than one schedule with different start/end dates.{br} \
			- The list is sorted from newest to oldest.{br} \
			- If dates overlap, the newest one is selected.{br} \
			- The active schedule is highlighted in GREEN.{br} \
			- Other valid schedules are in ORANGE.{br} \
			- Invalid/ended ones are in GREY.',
	},
	// TODO: zh
	'pages.ScreenManager.components.Schedules.PageTourStep3': {
		// eslint-disable-next-line no-multi-str
		en: 'SCHEDULE OPTIONS {br}{br} \
		A Schedules basic information like valid dates',
	},
	// TODO: zh
	'pages.ScreenManager.components.Schedules.PageTourStep4': {
		en:
			// eslint-disable-next-line quotes, no-multi-str
			"WEEKLY-REPEATING SCHEDULER {br}{br} \
			\
			A schedule's weekly repeating timetable. Lets you create timeslots and assign a playlist to them. \
			Just press and drag the mouse from one time to another. Once the whole week is covered, you can save it.{br}{br}\
			\
			TIPs: {br} \
					- Drag the mouse across days to duplicate same slot for other days too. {br} \
					- Press CTRL (CMD on MacOS) to select more than one scheduled timeslot to \
						edit/delete them in bulk. Just release the CTRL key after you are done {br}{br} \
			\
			NOTES: {br} \
			  - Do NOT release the mouse outside the timetable area. If you accidently do it, \
					click it on some empty timeslot.{br} \
				- To save the timetable, it should cover the whole week. Make sure to fill in time slots \
					from 00:00 all the way down to 24:00.",
	},
	'pages.ScreenManager.components.Schedules.unsavedChangesMsg': {
		en: 'There are unsaved changes. Do you really want to leave the page?',
		zh: '',
	},
	'pages.ScreenManager.components.Schedules.unsavedScheduleChangesMsg': {
		en: 'There are unsaved changes. Do you really want to change/add the schedule?',
		zh: '',
	},
	// screen slides
	'pages.ScreenManager.components.Slides.TitleCol': {
		en: 'Title',
		zh: '名称',
	},
	'pages.ScreenManager.components.Slides.FileTypeCol': {
		en: 'File Type',
		zh: '文件类型',
	},
	'pages.ScreenManager.components.Slides.DurationCol': {
		en: 'Duration',
		zh: '时长',
	},
	'pages.ScreenManager.components.Slides.ScheduleCol': {
		en: 'Schedule',
		zh: '时间安排',
	},
	'pages.ScreenManager.components.Slides.MediaCol': {
		en: 'Media',
		zh: '文件',
	},
	'pages.ScreenManager.components.Slides.ViewMediaText': {
		en: 'Edit Slide',
		zh: '查看文件',
	},
	'pages.ScreenManager.components.Slides.DuplicateSlideDialogTitle': {
		en: 'Duplicate Slide',
		zh: '复制页面',
	},
	'pages.ScreenManager.components.Slides.DuplicateSlideDialogMessage': {
		en: 'Are you sure you want to duplicate the slide {slideName} within the current Playlist?',
		zh: '确定要复制页面 - {slideName} - 到当前播放列表?',
	},
	'pages.ScreenManager.components.Slides.DuplicateSlideDoneMessage': {
		en: 'The selected slide has been duplicated to the end of the playlist.',
		zh: '页面已经成功被复制到当前列表最后',
	},
	'pages.ScreenManager.components.Slides.InsertSlideDoneMessage': {
		en: 'The selected slide has been inserted at the end of the playlist.',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.NotifyErrorNoDataChange': {
		en: 'No data has been changed',
		zh: '没有信息更改，不需要保存',
	},
	'pages.ScreenManager.components.Slides.DelSlideDialogTitle': {
		en: 'Delete Slide',
		zh: '删除页面',
	},
	'pages.ScreenManager.components.Slides.DelSlideDialogMessage': {
		en: 'Please confirm you want to delete the slide - {slideName}',
		zh: '请确认要删除页面 - {slideName}',
	},
	'pages.ScreenManager.components.Slides.PageTitle': {
		en: 'Manage Slides in Playlist {PLName}',
		zh: '管理{PLName}中的页面',
	},
	'pages.ScreenManager.components.Slides.SlideSchedule.DateSchedule': {
		en: 'From {startDT} to {endDT}',
		zh: '自 {startDT} 至 {endDT}',
	},
	'pages.ScreenManager.components.Slides.SlideSchedule.DayOfWeekSchedule': {
		en: 'On Every ',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.SlideSchedule.TimeSchedule': {
		en: 'Between {startTime} to {endTime}',
		zh: '每天 从 {startTime} 到 {endTime}',
	},
	'pages.ScreenManager.components.Slides.SlideSchedule.AllDayText': {
		en: 'All Day',
		zh: '每天',
	},
	'pages.ScreenManager.components.Slides.BulkScheduleTitle': {
		en: 'Bulk Scheduling',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.ResetBulkScheduleTitle': {
		en: 'Reset Slide Schedule',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.ResetBulkSchedule': {
		en: 'Please confirm you want to reset the scheduling for selected slides',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.AddSlides': {
		en: 'Add Slide',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.SelectMediaFile': {
		en: 'Select Media File',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.NotifyErrorFailedToAddSlide': {
		en: 'Failed to add a slide',
		zh: '',
	},
	'pages.ScreenManager.components.Slides.NotifyErrorFailedUpdateDuration': {
		en: 'Value {newDuration} is less than minimum {duration} at field - duration ',
		zh: '',
	},
	// Synchronised Playlist
	'pages.ScreenManager.components.Playlists.SyncPlaylist.SyncPlaylistTitle': {
		en: 'Synchronised Playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.AddPlaylistTooltipTitle': {
		en: 'Add Playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.RemovePlaylistTooltipTitle': {
		en: 'Remove Playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.AddGridTooltipTitle': {
		en: 'Add New Grid',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.RemoveGridTooltipTitle': {
		en: 'Remove Grid',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.NoSlidesInPlaylist': {
		en: 'Playlist does not contain any slides.',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.SaveBtnSuccess': {
		en: 'Playlists are Synchronised Successfully!',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.SelectPlaylistWarning': {
		en: 'Warning.',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.RemoveScheduleWarning': {
		en:
			'Important: Any custom scheduling applied to the slides in the selected playlists will be removed!',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.ResizeComp.NotifyErrorFailedUpdateDuration': {
		en: 'Value {newDuration} is less than the minimum duration of video - {duration}',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.AddSyncGroupNameTitle': {
		en: 'Enter a name for synchronised group',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.AddSyncGroupNameLabel': {
		en: 'Group name',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.AddSchedule': {
		en: 'Schedule',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.DeleteSlide': {
		en: 'Delete slide',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.SlideScheduler': {
		en: 'Scheduler',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.ResetBulkSchedule': {
		en: 'Please confirm you want to reset the scheduling for selected slides',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.ResetBulkScheduleTitle': {
		en: 'Reset Slide Schedule',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.RemovePlaylistTitle': {
		en: 'Remove Playlist',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.RemovePlaylist': {
		en: `Are you sure you want to remove this playlist?`,
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.DeleteSyncGroupTitle': {
		en: 'Delete Sync group',
		zh: '',
	},
	'pages.ScreenManager.components.Playlists.SyncPlaylist.DeleteSyncGroup': {
		en: `Are you sure you want to delete this sync group?`,
		zh: '',
	},
	// Player Universe
	'pages.ScreenManager.components.ScreenManager.PlayerUniverseNavName': {
		en: 'Player Universe',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.TableTitle': {
		en: 'Manage Player Universe',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.DomainCol': {
		en: 'Domain',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.CommentCol': {
		en: 'Comment',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.PlayCodeCol': {
		en: 'Player code',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel': {
		en: 'Add comment',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.PreviewCommentDialogLabel': {
		en: 'Preview comment',
		zh: '',
	},
	// 'pages.ScreenManager.components.PlayerUniverse.EditCommentDialogLabel': {
	// 	en: 'Edit comment',
	// 	zh: '',
	// },
	'pages.ScreenManager.components.PlayerUniverse.DeleteCommentDialogTitle': {
		en: 'Delete comment',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.DeleteCommentDialogMessage': {
		en: 'Please confirm you want to delete the comment',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.CommentEditedLabel': {
		en: 'Last Edited:',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.NotifyErrorMissingRequiredData': {
		en: 'Missing Data. Please enter the comment',
		zh: '',
	},
	'pages.ScreenManager.components.PlayerUniverse.NotifyErrorNoDataChange': {
		en: 'No data has been changed',
		zh: '',
	},
	// ##############################
	// Home page
	// #############################
	'pages.Home.Routes.Home': {
		en: 'Home',
		zh: '首页',
	},

	// ##############################
	// File Upload page
	// #############################
	'pages.FileUpload.Routes.FileUpload': {
		en: 'File Upload',
		zh: '文件上传',
	},
	'pages.FileUpload.components.FileUpload.ProcessMsgTitle': {
		en:
			'Your files have been uploaded and are now processing. It may take some time for all files to process - you will receive an email when processing is complete, and this email will contain any errors that may have occurred.',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ProcessMsgQueueing': {
		en: 'Your upload is queued for processing at position {sequenceNO}',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ProcessMsgProcessing': {
		en: 'Processing your upload',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ProcessMsgCompleted': {
		en: 'Finished processing your upload',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ProcessMsgFailed': {
		en: 'Error while processing your upload, check your email to see the details',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ActionSeeFiles': {
		en: 'See Files',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ActionContinueUploading': {
		en: 'Continue Uploading',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormUnzip': {
		en: 'Unzip ZIP Files:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormUserGroups': {
		en: 'Groups:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormSocialMedia': {
		en: 'Post to Social Media:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormScreenEnabled': {
		en: 'Enable for Screens:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormArtworkEnabled': {
		en: 'Artwork Template:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormCategory': {
		en: 'Category:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormKeywords': {
		en: 'Keywords:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormStartDate': {
		en: 'Start Date:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormPostingDate': {
		en: 'Posting Date:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.UploadFormExpiryDate': {
		en: 'Expiry Date:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.FormStatusText': {
		en: 'Status:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ContactAdminListText': {
		en: 'Contact Admin:',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ValidateFilesTitle': {
		en: 'Validating Your Files',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ValidateFilesMsg': {
		en: 'It could take a few minutes if the files are large ...',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.ValidateFilesErrorMsg': {
		en: 'Can not process your files: {uploadRequestErrMsg}',
		zh: '',
	},
	'pages.FileUpload.components.FileUpload.DisableUnzipWarningMsg': {
		en:
			'Unzip option is turned off and disabled. One or more zip files exceed max size ({maxSizeToUnzip}) to unzip. ',
		zh: '',
	},

	// ##############################
	// Artwork page
	// #############################
	// 'pages.Artwork.Routes.Artwork': {
	// 	en: 'Artwork',
	// 	zh: '设计',
	// },
	'pages.Artwork.GENERAL.typeText': {
		en: 'text',
		zh: '文本',
	},
	'pages.Artwork.GENERAL.typeImage': {
		en: 'image',
		zh: '图片',
	},
	'pages.Artwork.GENERAL.typePdf': {
		en: 'pdf',
		zh: 'pdf',
	},
	'pages.Artwork.GENERAL.typeBarcode': {
		en: 'barcode',
		zh: '二维码',
	},
	'pages.Artwork.GENERAL.typeVideo': {
		en: 'video',
		zh: '视频',
	},
	'pages.Artwork.GENERAL.typeGrid': {
		en: 'grid',
		zh: '网格',
	},
	'pages.Artwork.SHAREABLE.finishCroppingImageWarning': {
		en: 'Please finish cropping the image',
		zh: '',
	},
	'pages.Artwork.components.General.unsavedChangesMsg': {
		en: 'There is unsaved changes. Do you really want to leave the page?',
		zh: '',
	},
	'pages.Artwork.components.Design.emptyFieldsOnSaveErrMsg': {
		en: 'Template has no field to save. You may import template or create fields before saving it',
		zh: '',
	},
	'pages.Artwork.components.Design.delGroupWithFieldsTitle': {
		en: 'Delete Group Fields',
		zh: '',
	},
	'pages.Artwork.components.Design.delGroupWithFieldsContentMsg': {
		en: 'Do you want to delete "{groupName}" and its fields?',
		zh: '',
	},
	// 'pages.Artwork.components.Design.delGroupWithFieldsContentWarnMsg': {
	// 	en: 'The "Auto Import" in the following fields will be reset.',
	// 	zh: '',
	// },
	'pages.Artwork.components.Design.delFieldsTitle': {
		en: 'Delete Fields',
		zh: '',
	},
	'pages.Artwork.components.Design.delFieldsContentMsg': {
		en: 'Do you want to delete the {fieldsCount, plural, =1 {field} other {fields}}?',
		zh: '',
	},
	'pages.Artwork.components.Design.delFieldsAutoImportWarnMsg': {
		en: 'The "Auto Import" in the following fields will be reset.',
		zh: '',
	},
	'pages.Artwork.components.Design.delFieldsOutputDependsOnWarnMsg': {
		en: 'The "Output DependsOn" in the following fields will be reset.',
		zh: '',
	},
	'pages.Artwork.components.Design.delFieldsAffectCalcValueWarnMsg': {
		en: 'The "Calculation Value" in the following fields will be reset.',
		zh: '',
	},
	'pages.Artwork.components.Design.delFieldsAffectBarcodeAppendWarnMsg': {
		en: 'The "Append" in the following barcode fields will be reset.',
		zh: '',
	},
	'pages.Artwork.components.Design.delFieldsAffectConcatFieldWarnMsg': {
		en: 'The "Default Value" in the following concatenation fields will be reset.',
		zh: '',
	},
	'pages.Artwork.components.Design.delFieldsAffectPreSearchFieldWarnMsg': {
		en: 'The "Select Field for Pre-Search" in the following image fields will be reset.',
		zh: '',
	},
	'pages.Artwork.components.Design.draftTemplateDialogTitle': {
		en: 'Draft Template',
		zh: '',
	},
	'pages.Artwork.components.Design.draftTemplateDialogMsg1': {
		en: `There is previously unsaved draft template. Do you want to continue editing it?`,
		zh: '',
	},
	'pages.Artwork.components.Design.draftTemplateDialogMsg2': {
		en: 'Note: If you cancel it, the draft template will be deleted.',
		zh: '',
	},
	'pages.Artwork.components.Design.loadingFontFailureMsg': {
		en: 'Failed to load following fonts. Please contact to Admin to report the error.',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.shortcutText': {
		en: 'Shortcut Keys',
		zh: '快捷键',
	},
	'pages.Artwork.components.Sidemenu.gobackText': {
		en: 'Go Back',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelField.dragText': {
		en: 'Drag To Add {name}',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelField.fieldNameText': {
		en: 'Field Name',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelField.helpText': {
		en: 'Help Text',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelField.groupText': {
		en: 'Group',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelLayers.titleText': {
		en: 'Layers',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelLayers.emptyTemplateMsg': {
		en: 'Empty Template',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelLayers.groupNameLabelText': {
		en: 'Group Name',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.titleText': {
		en: 'Template Info',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.nameText': {
		en: 'Name',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.mediaIDText': {
		en: 'ID',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.widthText': {
		en: 'Width',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.heightText': {
		en: 'Height',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.ImportTemplate.titleText': {
		en: 'Import Template',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.ImportTemplate.autoCompletePlaceHolder': {
		en: 'Select template to import',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.ImportTemplate.importButtonText': {
		en: 'Import',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.ImportTemplate.noOptionsText': {
		en: 'No template',
		zh: '',
	},
	'pages.Artwork.components.Sidemenu.ImportTemplate.tipText': {
		en: 'To re-import a template, you need to delete all the fields in the template',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.undoText': {
		en: 'Undo',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.redoText': {
		en: 'Redo',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.testButtonText': {
		en: 'Test',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.saveButtonText': {
		en: 'Save',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.esignButtonText': {
		en: 'Esign',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.savingTemplateMsg': {
		en: 'Saving Design Template',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.previewTooltip': {
		en: 'Preview',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.backgroundTooltip': {
		en: 'Background',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.goBackTooltip': {
		en: 'Go to detail page',
		zh: '',
	},
	'pages.Artwork.components.BottomActionRibbon.fieldsHighlightTooltip': {
		en: 'Highlight Fields',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.title': {
		en: 'Preview Panel',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.videoLoopTooltip': {
		en: 'Loop',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.resetPreviewDataTooltip': {
		en: 'Reset All Fields',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.downloadPdfTooltip': {
		en: 'Download PDF',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.downloadSVGTooltip': {
		en: 'Download SVG',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.fontSizeTitle': {
		en: 'Font Size',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.customFontsizeNoneMsgGridField': {
		en: 'Customise font size',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.buttonChoose': {
		en: 'Choose',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.buttonReset': {
		en: 'Reset',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.buttonClear': {
		en: 'Clear',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.buttonImageClip': {
		en: 'Clip',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.buttonImageCrop': {
		en: 'Crop',
		zh: '',
	},
	'pages.Artwork.components.PreviewControlPanel.playAnimationTooltip': {
		en: 'Play Animation',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.titleText': {
		en: 'Settings',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.htmlArtworkSwitchLabel': {
		en: 'HTML Artwork',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.autoCreateArtworkSwitchLabel': {
		en: 'Auto Create Artwork',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.videoArtworkSwitchLabel': {
		en: 'Video Artwork',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.esignTemplateSwitchLabel': {
		en: 'Esign Template',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.esignOnlySwitchLabel': {
		en: 'Esign Only',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.animationDurationText': {
		en: 'Animation Duration',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.TemplateBGTitle': {
		en: 'Background',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.TemplateBGOringinLabel': {
		en: 'Background Origin',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.GeneralSettingTitle': {
		en: 'General',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.FilenameFieldLabel': {
		en: 'Filename Field',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.AppendFilenameFieldLabel': {
		en: 'Append Filename Field',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.VideoPreviewFieldLabel': {
		en: 'Video Preview Field',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.OutputTemplateSettingTitle': {
		en: 'Output Template',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.OutputTemplateSettingDisableMsg': {
		en: 'Disabled. No predefined spreadsheet',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.TemplateBGChoiceText': {
		en: 'Background Choice',
		zh: '',
	},
	'pages.Artwork.components.DesignSetting.TemplateBGCustomisableText': {
		en: 'Customizable',
		zh: '',
	},
	'pages.Artwork.components.GENERAL.secondShort': {
		en: 's',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.titleMultipleSelection': {
		en: 'Multi Selections',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.colorText': {
		en: 'Color',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.horAlignText': {
		en: 'Horizontal Align',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText': {
		en: 'User Defined',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.verAlignText': {
		en: 'Vertical Align',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.defaultValText': {
		en: 'Default Value',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.autoImportText': {
		en: 'Auto Import',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.basedOnText': {
		en: 'Based On',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.chooseCellText': {
		en: 'Choose Cell',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.notAvailableText': {
		en: 'Not Available',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.esignEditableText': {
		en: 'Esign Editable',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.esignSearchableText': {
		en: 'Esign Searchable',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.esignFilterText': {
		en: 'Esign Filter',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.labelText': {
		en: 'Label',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.displayOrderText': {
		en: 'Display Order In Its Group',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.groupText': {
		en: 'Group',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.helpTextLabel': {
		en: 'Help Text',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.controlsText': {
		en: 'Controls',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.hideInputText': {
		en: 'Hide Input:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.hideOutputText': {
		en: 'Hide Output:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.outputDependsOnText': {
		en: 'Output Depends On:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.allowRepositionText': {
		en: 'Allow Reposition:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.positionText': {
		en: 'Position',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.horXText': {
		en: 'Horizont X',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.verYText': {
		en: 'Vertical Y',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.widthText': {
		en: 'Width',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.heightText': {
		en: 'Height',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.rotationText': {
		en: 'Rotation',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.animationTitle': {
		en: 'Animation',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.animationEntranceText': {
		en: 'Entrance',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.animationDelayAfterText': {
		en: 'Delay after prev.',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.animationDurationText': {
		en: 'Duration',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.animationExitText': {
		en: 'Exit',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoTitle': {
		en: 'Insertion on Video',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoStartTimeText': {
		en: 'Start time',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoDurationText': {
		en: 'Duration',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoStartTimeWrongValueMsg': {
		en: `Wrong format in startTime. It must be "HH:MM:SS:FF"`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoFadeInText': {
		en: `Fade in`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoFadeOutText': {
		en: `Fade out`,
		zh: '',
	},

	'pages.Artwork.components.ElementPalette.GENERAL.alignmentText': {
		en: 'Alignment',
		zh: '',
	},

	'pages.Artwork.components.ElementPalette.GENERAL.borderWidthText': {
		en: 'Border Width',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.borderColorText': {
		en: 'Border Color',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.noImageFieldsText': {
		en: 'No Image Fields',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.imageColumnText': {
		en: 'Image Column',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.noCategoryAvailableText': {
		en: 'No category available',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.includeUserFilesText': {
		en: 'Include User Files',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.fontListNotAvailableText': {
		en: 'Font list is not available',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.fontSameAsTextMsg': {
		en: 'Same as Text',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.fontText': {
		en: 'Font',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.fontFaceText': {
		en: 'Font Face',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.fontSizeText': {
		en: 'Font Size',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.fontColorText': {
		en: 'Font Color',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.userDefinedStartText': {
		en: 'Start',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.userDefinedIncrementText': {
		en: 'Incr.',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.userDefinedEndText': {
		en: 'End',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.shadowText': {
		en: 'Shadow',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.shadowHorizontalText': {
		en: 'Horizontal',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.shadowVerticalText': {
		en: 'Vertical',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.shadowBlurRadiusText': {
		en: 'Blur Radius',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.typeText': {
		en: 'Type',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.chooseFromText': {
		en: 'Choose From',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.no_NAME_AvailableText': {
		en: 'No {name} Available',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.columnText': {
		en: 'Column',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.sameAsTitleHeaderText': {
		en: `Same as "Title Header"`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.titleHeaderSectionLabel': {
		en: `Title Header`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.titleCellsSectionLabel': {
		en: `Title Cells`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.valueHeaderSectionLabel': {
		en: `Value Header`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.valueCellsSectionLabel': {
		en: `Value Cells`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.allCapLabel': {
		en: `All Capital`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.userDefinedFontsizeLabel': {
		en: `User Defined Font Size`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.globalSectionLabel': {
		en: `Global`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.ruleSectionLabel': {
		en: `Rules`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.headerRuleLabel': {
		en: `Header Rule`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.rowsInBodyRuleLabel': {
		en: `Row Rule`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.subtitleFontsizeLabel': {
		en: `Subtitle Display At`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GridPalette.defaultGridDataLabel': {
		en: `Default Value`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.imageSizingText': {
		en: 'Image Sizing',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.clipImageText': {
		en: 'Clip Image',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.imageOriginText': {
		en: 'Image Origin',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.imageChoiceText': {
		en: 'Image Choice',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.preSearchFieldLabelText': {
		en: 'Select Field for Pre-Search',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.generateAISwitch': {
		en: 'Generate AI',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.generateAIPromptLabel': {
		en: 'Generate AI Prompt',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.ImagePalette.defaultImageMediaText': {
		en: 'Default Image Media',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.PdfPalette.anchorText': {
		en: 'Anchor',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.PdfPalette.anchorBottomText': {
		en: 'Bottom',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.PdfPalette.anchorTopText': {
		en: 'Top',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.PdfPalette.pdfSizingText': {
		en: 'PDF Sizing',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.PdfPalette.pdfOriginText': {
		en: 'PDF Origin',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.PdfPalette.pdfChoiceText': {
		en: 'PDF Choice',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.PdfPalette.defaultPdfMediaText': {
		en: 'Default PDF Media',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.inputStyleText': {
		en: 'Input Style',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.inputStyleSingleLineText': {
		en: 'Single Line',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.inputStyleTextboxText': {
		en: 'Text Box',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesTitleText': {
		en: 'Styles',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesDoNotSetToFitText': {
		en: 'Do Not set to fit:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesSmartQuotesText': {
		en: '“Smart Quotes”:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesRemoveLineBreaksText': {
		en: 'Remove Line Breaks:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesWrapLinesContainingSlashText': {
		en: `Wrap Lines Containing "/":`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesAllCapsText': {
		en: 'ALL CAPS:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesStrikeThroughText': {
		en: 'Strike Through:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesStrikeThroughLineWidthText': {
		en: 'Line Width',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesStrikeThroughTypeSampleText': {
		en: '€1.99',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberText': {
		en: 'Format Number:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencyText': {
		en: 'Currency',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencySymbolText': {
		en: 'Symbol',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberDisplayAtText': {
		en: 'Display at %',
		zh: '',
	},

	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencySymbolPositionLeftText': {
		en: '€1.99',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencySymbolPositionRightText': {
		en: '1.99€',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCentText': {
		en: 'Cent',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCentDisplayValueText': {
		en: 'Display Cent Value:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCentNotDisplay0CentsText': {
		en: 'DO NOT Display 0 Cents:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesBatchRepeatingFieldText': {
		en: 'Batch Repeating Field:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesBatchNumOfRepetitionsText': {
		en: 'Number of Repetitions',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesBatchRepeatingFieldDelimiterText': {
		en: 'Delimiter',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesTableColumnsText': {
		en: 'Table Columns:',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesTableColumnsFixedLabel': {
		en: 'Fixed',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.stylesTableColumnsFlexibleLabel': {
		en: 'Flexible',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.spacingText': {
		en: 'Letter Spacing',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.leadingText': {
		en: 'Leading {br}(Line Height)',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.predefinedValueText': {
		en: 'Predefined Value',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.allowOtherText': {
		en: 'Allow Other',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.outputTemplateText': {
		en: 'Output Template',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.viewOutputTemplateText': {
		en: 'View Output Template',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.altTemplateIDText': {
		en: 'Alternative Template ID',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.calValueTitle': {
		en: 'Calculate Value',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.calValuePriceText': {
		en: 'Price',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.calValueUnitText': {
		en: 'Unit',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.calValueQtyText': {
		en: 'Qty',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.calValuePerText': {
		en: 'Per',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.calValueHideText': {
		en: 'Hide',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.calValueMultipleWeightMsgText': {
		en: 'Multiple Weights Message',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleTitleText': {
		en: 'Embed Field Style',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleSelectFieldText': {
		en: 'Select Field To Embed',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleEmbeddingStyleText': {
		en: 'Embedding Style',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleLeadingStyleText': {
		en: 'Leading Style',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.viewSpreadsheetTooltip': {
		en: 'Open spreadsheet',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.disableAutoImportHelperLabel': {
		en: 'Auto-Import is disabled by Predefined/Embedded setting',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.disablePredefinedHelperLabel': {
		en: 'Predefined is disabled by AutoImport/Embedded setting',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.TextPalette.disableEmbeddedHelperLabel': {
		en: 'Embedded is disabled by AutoImport/Predefined setting',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.GENERAL.cannotLoadFontPrefixText': {
		en: `Can't load the font`,
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.BarcodePalette.appendText': {
		en: 'Append',
		zh: '',
	},
	'pages.Artwork.components.ElementPalette.BarcodePalette.ean5AddonText': {
		en: 'EAN5 Addon',
		zh: '',
	},
	'pages.Artwork.Constants.zoomFitText': {
		en: 'fit',
		zh: '',
	},
	'pages.Artwork.Constants.fieldDelimiterReturnLabel': {
		en: 'Return',
		zh: '',
	},
	'pages.Artwork.Constants.fieldDelimiterSpaceLabel': {
		en: 'Space',
		zh: '',
	},
	'pages.Artwork.Constants.embedStyleOptionsInheritLabel': {
		en: 'Adapt to this field',
		zh: '',
	},
	'pages.Artwork.Constants.embedStyleOptionsOriginalLabel': {
		en: 'Keep Original',
		zh: '',
	},
	'pages.Artwork.Constants.autoImportOtherOptionDateLabel': {
		en: `Today's Date`,
		zh: '',
	},
	'pages.Artwork.Constants.autoImportOtherOptionCellLabel': {
		en: 'Spreadsheet Cell',
		zh: '',
	},
	'pages.Artwork.Constants.predefinedValOptionListLabel': {
		en: 'From List',
		zh: '',
	},
	'pages.Artwork.Constants.predefinedValOptionSpreadsheetLabel': {
		en: 'From Spreadsheet',
		zh: '',
	},
	'pages.Artwork.Constants.autoImportOtherOptionCellimgLabel': {
		en: 'Spreadsheet Image',
		zh: '',
	},
	'pages.Artwork.Constants.imageOriginOptionsCategoryLabel': {
		en: 'Category',
		zh: '',
	},
	'pages.Artwork.Constants.imageOriginOptionsLightboxLabel': {
		en: 'Global Lightbox',
		zh: '',
	},
	'pages.Artwork.Constants.imageOriginOptionsUserFilesLabel': {
		en: `User's Own Files`,
		zh: '',
	},
	'pages.Artwork.Constants.imageOriginOptionsUserAvatarLabel': {
		en: `User's Avatar`,
		zh: '',
	},
	'pages.Artwork.Constants.imageSizingOptionsFitLabel': {
		en: 'Fit to Bounding Box',
		zh: '',
	},
	'pages.Artwork.Constants.imageSizingOptionsCropLabel': {
		en: 'Crop',
		zh: '',
	},
	'pages.Artwork.Constants.imageSizingOptionsAutoCropLabel': {
		en: 'Auto-Crop',
		zh: '',
	},
	'pages.Artwork.components.Create.requireFilenameErrorMsg': {
		en: 'File name is required',
		zh: '',
	},
	'pages.Artwork.components.Create.domainNameNotFoundErrorMsg': {
		en: `Can't find the domain name`,
		zh: '',
	},
	'pages.Artwork.components.Create.noValidFieldErrMsg': {
		en: `No valid field`,
		zh: '',
	},
	'pages.Artwork.components.Create.savingProgressMsgPreparing': {
		en: `Preparing artwork data...`,
		zh: '',
	},
	'pages.Artwork.components.Create.savingProgressMsgUploadingFiles': {
		en: `Uploading artwork files...`,
		zh: '',
	},
	'pages.Artwork.components.Create.savingProgressMsgSavingFinalArtwork': {
		en: `Saving final artwork...`,
		zh: '',
	},
	'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessful': {
		en: `Your artwork file has been saved successfully`,
		zh: '',
	},
	'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessfulAside': {
		en: `It may take some time to process and will be ready soon. `,
		zh: '',
	},
	'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessfulLinkText2': {
		en: ` to open the file`,
		zh: '',
	},
	'pages.Artwork.components.Create.savingProgressErrMsg': {
		en: `Failed to save your artwork. {errMsg}`,
		zh: '',
	},
	'pages.Artwork.components.Create.savingSuccessfulMsg': {
		en: `Your artwork file has been saved successfully. `,
		zh: '',
	},
	'pages.Artwork.components.Create.savingSuccessfulToViewText': {
		en: ` to view file`,
		zh: '',
	},
	'pages.Artwork.components.Create.clickHereBtnText': {
		en: `Click here`,
		zh: '',
	},
	'pages.Artwork.components.Create.AIImageUploadMsg': {
		en: `Preparing AI data...`,
		zh: '',
	},
	'pages.Artwork.components.Create.AIImageUploadError': {
		en: `Failed to save AI generated image.`,
		zh: '',
	},
	'pages.Artwork.components.Create.CropImageError': {
		en: `Can't save cropped images {errMsg}`,
		zh: '',
	},
	'pages.Artwork.components.CustomMUI.noneMsg': {
		en: 'None',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.DesignButtonTooltip': {
		en: 'Design',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueButtonTooltip': {
		en: 'Print Queue',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogQuestionText': {
		en: 'How many copies of this file would you like to print?',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogQtyText': {
		en: 'Specify quantity: ',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogTotalNumText': {
		en: `Your current print queue contains <bold>{totalNumOfFilesInPrintQueue, plural, =1 {# item} other {# items}}</bold>. Click "Print" to process and clear the queue.`,
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogPrintNowButtonText': {
		en: 'Print Now',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogResetPrintQueueButtonText': {
		en: 'Reset Queued Items',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogPrintQueuedItemsButtonText': {
		en: 'Print Queued Items',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogAddToQueueButtonText': {
		en: 'Add to Queue',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.PrintQueueDialogTitleText': {
		en: 'Print File',
		zh: '',
	},
	'pages.Artwork.components.CreateActions.SavePdfDialogTitleText': {
		en: 'Save this file as:',
		zh: '',
	},
	'pages.Artwork.components.InvalidFieldsMUIPopper.InvalidFieldsTitleText': {
		en: 'Invalid Fields',
		zh: '',
	},
	'pages.Artwork.components.InvalidFieldsMUIPopper.NoInvalidFieldsMsg': {
		en: 'No Invalid Field',
		zh: '',
	},
	'pages.Artwork.components.Esign.PageTitle': {
		en: 'Product Picker',
		zh: '',
	},
	'pages.Artwork.components.Esign.ShowMySelectionBtnText': {
		en: 'Show My Selection',
		zh: '',
	},
	'pages.Artwork.components.Esign.ShowAllBtnText': {
		en: 'Show All',
		zh: '',
	},
	'pages.Artwork.components.Esign.CreateOwnBtnText': {
		en: 'Create Your Own',
		zh: '',
	},
	'pages.Artwork.components.Esign.PrintBtnText': {
		en: 'Print {totalSelections}',
		zh: '',
	},
	'pages.Artwork.components.Esign.PrintSearchBtnText': {
		en: 'Print Search Result',
		zh: '',
	},
	'pages.Artwork.components.Esign.PrintAllBtnText': {
		en: 'Print All',
		zh: '',
	},
	'pages.Artwork.components.Esign.BatchBtnText': {
		en: 'Batch Save',
		zh: '',
	},
	'pages.Artwork.components.Esign.BatchDialogTitle': {
		en: 'Batch Artwork',
		zh: '',
	},
	'pages.Artwork.components.Esign.BatchFileNameColSelectLabel': {
		en: 'Choose File Name Field - {templateName}',
		zh: '',
	},
	'pages.Artwork.components.Esign.BatchUserColSelectLabel': {
		en: 'Choose Owner Field',
		zh: '',
	},
	'pages.Artwork.components.Esign.BatchDialogInstructionText': {
		en:
			'Batching artwork will only create one file for each selected item, and ignore the specified quantity',
		zh: '',
	},
	'pages.Artwork.components.Esign.BatchingFinishMsg': {
		en: `Batching artwork has been completed.`,
		zh: '',
	},
	'pages.Artwork.components.Esign.BatchingTotalFailedMsg': {
		en: `{totalFailed}/{totalFiles} files were failed processing. {totalInvalid}/{totalFiles} files were invalid.`,
		zh: '',
	},
	'pages.Artwork.components.Esign.ClearAllBtnText': {
		en: 'Clear All',
		zh: '',
	},
	'pages.Artwork.components.Esign.SpreadsheetBtnText': {
		en: 'Spreadsheet',
		zh: '',
	},
	'pages.Artwork.components.Esign.ProofBtnText': {
		en: 'Proof',
		zh: '',
	},
	'pages.Artwork.components.Esign.FilterBtnText': {
		en: '{filterCount, plural, =0 {No Filter} =1 {Filter} other {Filters}}',
		zh: '',
	},
	'pages.Artwork.components.Esign.MoreTemplatesBtnText': {
		en: 'Add Templates',
		zh: '',
	},
	'pages.Artwork.components.Esign.AdjustOutputTipText': {
		en: 'Change this value to shift printed output up or down the page if necessary.',
		zh: '',
	},
	'pages.Artwork.components.Esign.AdjustOutputText': {
		en: 'Adjust Output',
		zh: '',
	},
	'pages.Artwork.components.Esign.ViewSpreadsheetTitle': {
		en: 'View Spreadsheet',
		zh: '',
	},
	'pages.Artwork.components.Esign.ViewSpreadsheetConfirmText': {
		en: `Are you sure you want to view this template's spreadsheet?`,
		zh: '',
	},
	'pages.Artwork.components.Esign.ClearAllDialogTitle': {
		en: 'Clear all selections',
		zh: '',
	},
	'pages.Artwork.components.Esign.ClearAllDialogContent': {
		en: 'Are you sure you want to clear all items from your selection?',
		zh: '',
	},
	'pages.Artwork.components.Esign.CreateOwnDialogTitle': {
		en: 'Open template page',
		zh: '',
	},
	'pages.Artwork.components.Esign.CreateOwnDialogContent': {
		en: 'Are you sure you want to open the template page?',
		zh: '',
	},
	'pages.Artwork.components.Esign.NoTemplateDataErrorMsg': {
		en: 'Not found the template data',
		zh: '',
	},
	'pages.Artwork.components.Esign.PrintAllDialogTitle': {
		en: `Print All - "{templateName}"`,
		zh: '',
	},
	'pages.Artwork.components.Esign.PrintSelectionsDialogTitle': {
		en: 'Print Selected',
		zh: '',
	},
	'pages.Artwork.components.Esign.PrintSearchResultDialogTitle': {
		en: 'Print Search Result',
		zh: '',
	},
	'pages.Artwork.components.Esign.TooManySelectionsDialogTitle': {
		en: 'Warning',
		zh: '',
	},
	'pages.Artwork.components.Esign.TooManySelectionsDialogContent': {
		en:
			'We would strongly advise to not select more than {maxItems} items at a time - print your selection now!',
		zh: '',
	},
	'pages.Artwork.components.Esign.WarningMessageSingleFilter': {
		en:
			'The filter for this template contains more than 100 options. Only the first 100 options are available.',
		zh: '',
	},
	'pages.Artwork.components.Esign.WarningMessageMultiFilter': {
		en:
			'One of the filters for this template contains more than 100 options. Only the first 100 options are available.',
		zh: '',
	},
	// ##############################
	// NotFound page
	// #############################
	'pages.NotFound.OopsMsg': {
		en: `Oops. Looks like the page you're looking for no longer exists`,
		zh: '您访问的页面不存在',
	},
	'pages.NotFound.BackSafeMsg': {
		en: `But we're here to bring you back to safety`,
		zh: '点击下方按钮返回首页',
	},
	'pages.NotFound.BackHomeButtonText': {
		en: 'Back to Home',
		zh: '返回首页',
	},

	// ##############################
	// Login page
	// #############################
	'pages.Login.components.LoginForm.LoginText': {
		en: 'Login',
		zh: '登录',
	},
	'pages.Login.components.LoginForm.EnterUNPWText': {
		en: 'Please enter your username and password:',
		zh: '请输入用户名和密码：',
	},
	'pages.Login.components.LoginForm.UsernameLabel': {
		en: 'Username',
		zh: '用户名',
	},
	'pages.Login.components.LoginForm.PWLabel': {
		en: 'Password',
		zh: '密码',
	},
	'pages.Login.components.LoginForm.ForgotPWText': {
		en: 'Forgot your password?',
		zh: '忘记密码？',
	},
	'pages.Login.components.ForgetPWForm.TitleText': {
		en: 'Password Retrieval',
		zh: '找回帐号密码',
	},
	'pages.Login.components.ForgetPWForm.EnterEmailText': {
		en: 'Please enter your login email address below.',
		zh: '请输入您的登录邮箱',
	},
	'pages.Login.components.ForgetPWForm.DescriptionText': {
		en:
			'A unique link will be sent to your email address and this link will allow you to change your password.',
		zh: '重置密码链接会发送到您的邮箱中，您需要点击链接来重置密码',
	},
	'pages.Login.components.ForgetPWForm.EmailLabel': {
		en: 'Email',
		zh: '邮箱地址',
	},
	'pages.Login.components.ForgetPWForm.SubmitText': {
		en: 'Submit',
		zh: '提交',
	},
	'pages.Login.components.ForgetPWForm.BackToLoginText': {
		en: 'Back to login',
		zh: '返回登录',
	},
	'pages.Login.components.LoginForm.ErrorMessage': {
		en: 'You have entered an invalid username or password',
		zh: '',
	},
	'pages.Login.components.LoginForm.ErrorStatusCode401': {
		en: 'Password is incorrect.',
		zh: '',
	},
	'pages.Login.components.LoginForm.ErrorStatusCode404': {
		en: 'User not found.',
		zh: '',
	},
	'pages.Login.components.LoginForm.ErrorStatusCode409': {
		en: 'More than one user account was found.',
		zh: '',
	},
	'pages.Login.components.LoginForm.ErrorStatusCode440': {
		en: 'User account has expired.',
		zh: '',
	},
	'pages.Login.components.LoginForm.ErrorStatusCode451': {
		en: 'User account is not authorised.',
		zh: '',
	},
	// ##############################
	// components
	// #############################
	'components.RTTrComp.DragToChangePosition': {
		en: 'Drag to change position',
		zh: '拖放更改位置',
	},
	'components.ErrorComp.OopsMsg': {
		en: 'Ooops, something is wrong',
		zh: '出问题啦！！！',
	},
	'components.HeaderBar.HeaderLinks.ProfileText': {
		en: 'Profile',
		zh: '账户信息',
	},
	'components.HeaderBar.HeaderLinks.LogoutText': {
		en: 'Logout',
		zh: '退出',
	},
	'components.HeaderBar.HeaderLinks.startleLoginText': {
		en: 'Go to Startle',
		zh: '',
	},
	'components.CreatableSelection.placeholderText': {
		en: 'Type to search/create',
		zh: '',
	},
	'components.CreatableSelection.noOptionsMsg': {
		en: 'No Option. Type to create one',
		zh: '',
	},
	'components.S3Uploader.DragNDropMsg': {
		en: `Drag 'n' drop some files here, or click to select files`,
		zh: '',
	},
	'components.S3Uploader.RejectedFilesMsg': {
		en: '{numOfRejectedFiles, plural, =1 {# file is} other {# files are}} rejected',
		zh: '',
	},
	'components.S3Uploader.UploadingFilesMsg': {
		en: '{numUploadingFiles, plural, =1 {# file} other {# files}} ({totalFileSize}) to be uploaded',
		zh: '',
	},
	'components.S3Uploader.UploadingFilesTipMsg': {
		en: 'max files: {maxFiles}, max combined size: {maxTotalSize}.',
		zh: '',
	},
	'components.S3Uploader.SupportedFileListText': {
		en: 'Supported File List',
		zh: '',
	},
	'components.S3Uploader.ButtonTextSelectFiles': {
		en: 'Select Files',
		zh: '',
	},
	'components.S3Uploader.ButtonTextStartUpload': {
		en: 'Start Upload',
		zh: '',
	},
	'components.S3Uploader.ButtonTextCancelUpload': {
		en: 'Cancel Upload',
		zh: '',
	},
	'components.S3Uploader.RejectedFilesSectionTitle': {
		en: 'Rejected Files',
		zh: '',
	},
	'components.S3Uploader.RemoveRejectedFilesTooltip': {
		en: 'Remove Rejected Files',
		zh: '',
	},
	'components.S3Uploader.AcceptedFilesSectionTitle': {
		en: 'Accepted Files',
		zh: '',
	},
	'components.S3Uploader.UploadingErrorMsg': {
		en: 'Error: {errorMsg}',
		zh: '',
	},
	'components.S3Uploader.ChooseActionDialogTitle': {
		en: 'CHOOSE YOUR ACTION',
		zh: '',
	},
	'components.S3Uploader.ConfimationText1': {
		en: 'Some of the files failed to upload:',
		zh: '',
	},
	'components.S3Uploader.ConfimationText2': {
		en: `Select "CONTINUE" to retain the uploaded files`,
		zh: '',
	},
	'components.MediafileMultiChoosers.ValidateFilesTitle': {
		en: 'Validating Your Files',
		zh: '',
	},
	'components.MediafileMultiChoosers.ValidateFilesMsg': {
		en: 'It could take a few minutes if the files are large ...',
		zh: '',
	},
	'components.MediafileMultiChoosers.ValidateFilesErrorMsg': {
		en: 'Error: {errMsg}',
		zh: '',
	},
	'components.MediafileMultiChoosers.UploadSuccessText': {
		en: 'Successfully uploaded',
		zh: '',
	},
	'components.MediafileMultiChoosers.ToolkitFileUploaderProviderLabel': {
		en: 'Upload',
		zh: '上传文件',
	},
	'components.MediafileMultiChoosers.ToolkitFileLibraryProviderLabel': {
		en: 'Library',
		zh: '图库',
	},
	'components.MediafileMultiChoosers.ToolkitMyfilesProviderLabel': {
		en: 'My Files',
		zh: '我的文件',
	},

	'components.GenerateImage.GenerateButton': {
		en: 'Generate',
		zh: '',
	},
	'components.GenerateImage.GenerateImageText': {
		en: 'Enter your text here',
		zh: '',
	},
	'components.GenerateImage.LoadingText': {
		en: 'Image generating please wait...',
		zh: '',
	},
	'components.GenerateImage.GenerateImageDialogTitle': {
		en: 'Generate AI Images',
		zh: '',
	},
	'components.GenerateImage.GenerateImageDialogContent': {
		en: 'Previous generated images will be deleted. Are you sure you want continue?',
		zh: '',
	},
	'components.GenerateImage.NoPromptError': {
		en: 'Missing prompt. Please enter the text to generate images',
		zh: '',
	},
	'components.GenerateImage.OpenAIError': {
		en: 'Can not generate AI images, please wait for few minutes and try again later.',
		zh: '',
	},
	// ##############################
	// MediaFiles
	// #############################
	'pages.MediaFiles.components.MediafileFilters.FilterBtnText': {
		en: 'Click to Filter',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.SearchFieldText': {
		en: 'Type to Search...',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.ApplyBtnText': {
		en: 'Apply',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.OkBtnText': {
		en: 'Ok',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.GridViewTooltip': {
		en: 'Switch to Grid View',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.TableViewTooltip': {
		en: 'Switch to Table View',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep1.h3': {
		en:
			// eslint-disable-next-line no-multi-str
			'SEARCH AND FILTER ALL FILES IN THE TOOLKIT',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep1.p': {
		en:
			// eslint-disable-next-line no-multi-str
			'(This help guide can always be activated by clicking this button) {br}{br} \
			\
			On this page you can drill down through all the files on the Toolkit to find the ones you need.',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep2.h3': {
		en:
			// eslint-disable-next-line no-multi-str
			'FILTER SEARCH RESULTS',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep2.p': {
		en:
			// eslint-disable-next-line no-multi-str
			'Click this button to reveal a dropdown where you can specify the type of file you are looking for, e.g. editable templates, videos or images, etc.',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep3.h3': {
		en:
			// eslint-disable-next-line no-multi-str
			'ENTER SEARCH TERMS',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep3.p': {
		en:
			// eslint-disable-next-line no-multi-str
			'Start typing in this field and you will see matching terms that you can click on, or continue typing to enter your own query.',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep4.h3': {
		en:
			// eslint-disable-next-line no-multi-str
			'VIEW AS ICONS OR AS LIST',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileFilters.PageTourStep4.p': {
		en:
			// eslint-disable-next-line no-multi-str
			'Click this button to switch the results view between a grid of previews and a detailed list.',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.FetchDetailsErrMsg': {
		en: `Failed to find the mediafile details. {errMsg}`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.FetchDomainMetaDataErrMsg': {
		en: `Failed to fetch metadatas. {errMsg}`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.BackToPrevToolTip': {
		en: `Go back`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.DownloadFileToolTip': {
		en: `Download the file`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.DeleteFileToolTip': {
		en: `Delete the file`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.AddToFolderToolTip': {
		en: `Add to folder «{folderName}»`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.NoDetailInfoMsg': {
		en: `No Mediafile Detail Info!`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.NoPreviewImageErrorMsg': {
		en: `No preview image found!`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.DeleteFileSuccessMsg': {
		en: `File has been deleted successfully`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.FailedToDeleteFileErrorMsg': {
		en: `Failed to delete file: {errMsg}`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.DownloadMediafileWrongOriginalUrl': {
		en: `Can't download the mediafile. Please report to Admin with mediafile id: {mediafileId}`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.FailedToGetDownloadUrlMsg': {
		en: `Failed to download the mediafile. {errMsg}`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.ArtworkPanelDesc': {
		en: `Choose an Artwork Option`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.ArtworkPersonaliseBtnText': {
		en: `Personalise`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.ArtworkDesignBtnText': {
		en: `Design`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.ArtworkModifyBtnText': {
		en: `Modify`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.FileListTypeHeader': {
		en: `Type`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.FileListSizeHeader': {
		en: `Size`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.FileListActionHeader': {
		en: `Action`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.FileListLowResText': {
		en: `Low Res Image`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.FileListDimensionsText': {
		en: `Dimensions`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.BtnTextShowPlaylist': {
		en: `Show Playlists Displaying This File`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.BtnTextShowImagesUsed': {
		en: `Show Images Used in This File ({numImages})`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.categoryNameLabel': {
		en: `Category`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.CoLocatedLabel': {
		en: `Co-located`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.storeArtworkInLabel': {
		en: `Store Artwork in`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.titleLabel': {
		en: `Title`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.statusLabel': {
		en: `Status`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.groupsLabel': {
		en: `Groups`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.mediafileIDLabel': {
		en: `Mediafile ID`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.pricingCategoryLabel': {
		en: `Pricing Category`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.merchantLabel': {
		en: `Merchant`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.screenDisplayLabel': {
		en: `Screen Display`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.artworkTemplateLabel': {
		en: `Artwork Template`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.postToSocialMediaLabel': {
		en: `Post to Social Media`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.postingDateLabel': {
		en: `Posting Date`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.templateLabel': {
		en: `Template`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.templateGroupLabel': {
		en: `Template Group`,
		zh: '',
	},

	'pages.MediaFiles.components.MediafileDetailsInfoTabs.relatedPagesLabel': {
		en: `Related Pages`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.numPagesLabel': {
		en: `No. of Pages`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.keywordsLabel': {
		en: `Keywords`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.dimensionsLabel': {
		en: `Dimensions`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.periodLabel': {
		en: `Period`,
		zh: '',
	},

	'pages.MediaFiles.components.MediafileDetailsInfoTabs.agencyNameLabel': {
		en: `Agency Name`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.regionLabel': {
		en: `Region`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.quantityLabel': {
		en: `Quantity`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.priceLabel': {
		en: `Price`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.orderDateLabel': {
		en: `Order Date`,
		zh: '',
	},

	'pages.MediaFiles.components.MediafileDetailsInfoTabs.prePrintedPaperLabel': {
		en: `Pre printed paper`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.uploadedByLabel': {
		en: `Uploaded By`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.modifiedByLabel': {
		en: `Modified By`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.sharedWithLabel': {
		en: `Shared With`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.startDateLabel': {
		en: `Start Date`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.expiryDateLabel': {
		en: `Expiry Date`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.addMoreMetaDataLabel': {
		en: `Add More MetaData`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.selectToAddMetaDataLabel': {
		en: `Select MetaData`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.noMissingMetaDataText': {
		en: `No Missing MetaData`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.addMetaDataBtnText': {
		en: `Add MetaData`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.artworkGeneratedFromText': {
		en: `This Artwork was generated from template`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.categoriesFetchErrorMsg': {
		en: `Can not load categories, please try again later...`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.userGroupsFetchErrorMsg': {
		en: `Can not load user groups, please try again later...`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.fetchPricingCategoriesErrorMsg': {
		en: `Can not load pricing categories, please try again later...`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.selectDateLabel': {
		en: `Choose date`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.noAvailableOptionsMsg': {
		en: `No options available`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.selectFileBtnText': {
		en: `Select File`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.addExtraFileBtnText': {
		en: `Add File`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.replaceFileBtnText': {
		en: `New Version`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.replaceFileLabelText': {
		en: `Replace this file with new version`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.replaceFileConfirmMsg': {
		en: `Are you sure you want to replace this file with a new version?`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetailsInfoTabs.extraFileConfirmMsg': {
		en: `Are you sure you want to add a different file to this existing one rather than replacing it with a new version?`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.NoMediafileMsg': {
		en: 'No media files were found',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.ZoomInTooltip': {
		en: 'Show Large Preview',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.GotoDetailsTooltip': {
		en: 'Details',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.DownloadFileTooltip': {
		en: 'Download This File',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.CustomizeArtworkTooltip': {
		en: 'Customise Artwork',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.EditTemplateTooltip': {
		en: 'Edit Template',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.GenerateArtworkTooltip': {
		en: 'Generate Artwork',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.AutoCreateChipTooltip': {
		en: '{count, plural, =0 {Generate File} other {View created file}}',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.AutoCreateChipLabelCreated': {
		en: 'Created',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.AutoCreateChipLabelFileNotCreated': {
		en: 'Produce File',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.AutoCreateChipLabelVideoNotCreated': {
		en: 'Produce Video',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.AddToScreenPlaylistTooltip': {
		en: 'Add to Screen Playlist',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.ShareActionTooltip': {
		en: 'Share to Social Media',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.OrderActionTooltip': {
		en: 'Add to Shopping Cart',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.ItemsPerPageLabel': {
		en: 'Items per page:',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.UnknowViewModeErrMsg': {
		en: 'Unknown View Mode',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.InvalidURLParams': {
		en: 'An invalid search parameter was encountered while loading this page.',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.RemoveFromCollection': {
		en: `Remove from Collection - {name}`,
		zh: '',
	},
	'pages.MediaFiles.components.MediafileDetails.AddToCollection': {
		en: `Add to Collection - {name}`,
		zh: '',
	},
	// 'pages.MediaFiles.components.MediafileTableView.ToggleSelectAllRows': {
	// 	en: 'Toggle All Rows Selected',
	// 	zh: '',
	// },
	// 'pages.MediaFiles.components.MediafileTableView.ToggleSelectRow': {
	// 	en: 'Toggle Row Selected',
	// 	zh: '',
	// },
	'pages.MediaFiles.components.MediafileTableView.TableHeaderPreview': {
		en: 'Preview',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileTableView.TableHeaderTitle': {
		en: 'Title',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileTableView.TableHeaderLastModified': {
		en: 'Last Modified',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileTableView.TableHeaderCategory': {
		en: 'Category',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileTableView.TableHeaderSize': {
		en: 'Size',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileTableView.TableHeaderFiletype': {
		en: 'File Type',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileTableView.TableHeaderGroups': {
		en: 'Groups',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileTableView.TableHeaderActions': {
		en: 'Actions',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.ScreensMenuListDialogTitle': {
		en: 'Not ready to add to Screen Playlist',
		zh: '',
	},
	'pages.MediaFiles.components.MediafileList.ScreensMenuListDialogContent': {
		en:
			'You need to personalise this item before you can add it to a Screen Playlist - Do you want to do this now?',
		zh: '',
	},
	// ##############################
	// File explorer page
	// #############################
	'pages.FileExplore.Routes.FileExplore': {
		en: 'File Explore',
		zh: '',
	},

	// Domain Settings page
	'pages.EditDomainSettings.Routes.EditDomainSettings': {
		en: 'Domain Settings',
		zh: '',
	},
	'pages.EditDomainSettings.EditDomainSettingsList.NameCol': {
		en: 'Name',
		zh: '',
	},
	'pages.EditDomainSettings.EditDomainSettingsList.DecriptionCol': {
		en: 'Description',
		zh: '',
	},
	'pages.EditDomainSettings.EditDomainSettingsList.SettingUserLevelCol': {
		en: 'Min User Level',
		zh: '',
	},
	'pages.EditDomainSettings.EditDomainSettingsList.ValueCol': {
		en: 'Value',
		zh: '',
	},
	// ##############################
	// User message page
	// #############################
	'pages.UserMessages.Routes.UserMessages': {
		en: 'Messages',
		zh: '',
	},
	'pages.UserMessages.components.UserMessagesTable.TableHeaderSubject': {
		en: 'Subject',
		zh: '',
	},
	'pages.UserMessages.components.UserMessagesTable.TableHeaderSenderName': {
		en: 'Sender Name',
		zh: '',
	},
	'pages.UserMessages.components.UserMessagesTable.TableHeaderSenderEmail': {
		en: 'Sender Email',
		zh: '',
	},
	'pages.UserMessages.components.UserMessagesTable.TableHeaderDate': {
		en: 'Date',
		zh: '',
	},
	'pages.UserMessages.components.UserMessagesTable.TableHeaderActions': {
		en: 'Actions',
		zh: '',
	},
	'pages.UserMessages.components.UserMessagesList.NoUserMsg': {
		en: 'No messages were found',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.ItemsPerPageLabel': {
		en: 'Items per page:',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.DeleteMsg': {
		en: 'Delete This Message',
		zh: '',
	},
	'pages.UserMessages.components.UserMessagesPreview.MsgStatus': {
		en: 'Mark as Unread',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.DeleteMsgTitle': {
		en: 'Delete message',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.DeleteMsgContent': {
		en: 'Please confirm you want to delete this message - this cannot be undone',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.BulkDeleteMsgTitle': {
		en: 'Delete message. You have selected {noOfMessages} messages',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.BulkDeleteMsg': {
		en: 'Please confirm you want to delete the selected messages',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.SelectedAsRead': {
		en: 'Mark selected as Read',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.SelectedAsUnRead': {
		en: 'Mark selected as Unread',
		zh: '',
	},
	'pages.UserMessages.components.UserMessages.DeleteSelected': {
		en: 'Delete all selected',
		zh: '',
	},
	// ##############################
	// My Files
	// #############################
	'pages.MyFiles.Routes.MyFilesTitle': {
		en: 'My Files',
		zh: '',
	},
	'pages.MyFiles.components.MyFilesTable.Files': {
		en: 'Files',
		zh: '',
	},
	// 'pages.MyFiles.components.MyFilesTable.TableHeaderSenderName': {
	// 	en: 'Sender Name',
	// 	zh: '',
	// },
	// 'pages.MyFiles.components.MyFilesTable.TableHeaderSenderEmail': {
	// 	en: 'Sender Email',
	// 	zh: '',
	// },
	'pages.MyFiles.components.MyFilesTable.TableHeaderDate': {
		en: 'Uploaded Date',
		zh: '',
	},
	'pages.MyFiles.components.MyFilesTable.TableHeaderActions': {
		en: 'Actions',
		zh: '',
	},
	'pages.MyFiles.components.MyFilesList.NoFilesMsg': {
		en: 'No files were found',
		zh: '',
	},
	'pages.MyFiles.components.MyFiles.ItemsPerPageLabel': {
		en: 'Items per page:',
		zh: '',
	},
	'pages.MyFiles.components.MyFiles.DeleteMsg': {
		en: 'Delete This Message',
		zh: '',
	},
	'pages.MyFiles.components.MyFilesPreview.MsgStatus': {
		en: 'Mark as Unread',
		zh: '',
	},
	'pages.MyFiles.components.MyFiles.DeleteMsgTitle': {
		en: 'Delete message',
		zh: '',
	},
	'pages.MyFiles.components.MyFiles.DeleteMsgContent': {
		en: 'Please confirm you want to delete this message - this cannot be undone',
		zh: '',
	},
	'pages.MyFiles.components.MediaFiles.DownloadAllButton': {
		en: 'Download All',
		zh: '',
	},
	'pages.MyFiles.components.MyFilesList.NoFilesDownloadMsg': {
		en: 'No files were found to download',
		zh: '',
	},
	'pages.MyFiles.components.MediaFiles.DownloadAllConfirmation': {
		en:
			// eslint-disable-next-line no-multi-str
			'Do you really want to download all {numberOfFiles} files shown on this page? {br} \
	\
	The total file size will be {size}.\
	',
		zh: '',
	},
	'pages.MyFiles.components.MediaFiles.DownloadOneConfirmation': {
		en: 'Do you really want to download the file shown on this page?',
		zh: '',
	},
	'pages.MyFiles.components.MediaFiles.DownloadSizeError': {
		en:
			// eslint-disable-next-line no-multi-str
			'You can’t download all {x} files on this page; total file size would be more than 1GB {br} \
	\
	Try reducing the number of files shown on the page or download one by one.\
	',
		zh: '',
	},
	// ##############################
	// Admin
	// #############################
	'pages.Admin.Routes.Admin': {
		en: 'Admin',
		zh: '',
	},
	'pages.Admin.Routes.PlayerUniverse': {
		en: 'Player Universe',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.SysCommandsCol': {
		en: 'Commands',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.HasRadioCol': {
		en: 'Check Radio',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.RadioIcon': {
		en: 'Click to check radio',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.CommandRowOrderHeader': {
		en: 'Order',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.CommandRowOptionsHeader': {
		en: 'Options',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.CommandRowNameHeader': {
		en: 'Name',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.NoCommandMsg': {
		en: 'No command, click button below to add command',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.AddCommandTooltip': {
		en: 'Add command',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.CommandDialogTitle': {
		en: 'Commands - {playerName}',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.BatchSetCommandDialogTitle': {
		en: 'Batch Set Command',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.BatchAddCommentDialogTitle': {
		en: 'Batch Add Comment',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.AddCommentTooltip': {
		en: 'Add comment',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.CommentDialogTitle': {
		en: 'Comment - {playerName}',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.NoCommentMsg': {
		en: 'No comment, click button below to add comment',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.SysSettingDialogTitle': {
		en: 'System Settings - {playerName}',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.ScreenshotCol': {
		en: 'Screenshot',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.ScreenshotTimestampText': {
		en: 'Taken at {timestamp}',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.MissingRequiredCommandOptionsMsg': {
		en: 'Missing Required Options',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.LinuxBoxSysVersionText': {
		en: 'System Service Version',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumVerLabel': {
		en: 'Chromium Version',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumLinuxVerLabel': {
		en: 'Chromium Linux Full Version Name',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.LinuxBoxServiceFetchReleaseErrorNotify': {
		en: 'Can not fetch boxService versions. {errorMsg}',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumValidationErrorNotify': {
		en: 'Both chromium and chromium linux versions are required',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.FailToSaveAppVersionErrorNotify': {
		en: 'Failed to save app version. {errorMsg}',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.SuccessToSaveAppVersionNotify': {
		en: 'Successfully saved app version.',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.LinuxBoxSysSettingDialogTitle': {
		en: 'LinuxBox system setting - {playerName}',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.ItemsPerPageLabel': {
		en: 'Items per page:',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.FiltersBtnText': {
		en: 'Filters',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.MapsTitle': {
		en: 'Maps',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.HasRadioBtnText1': {
		en: 'This location has a radio.',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.clickHereBtnText': {
		en: 'Click here',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.HasRadioBtnText2': {
		en: ' to redirect to startle',
		zh: '',
	},
	'pages.Admin.components.PlayerUniverse.HasRadioBtnError': {
		en: 'This location does not have a radio.',
		zh: '',
	},
	// ##############################
	// notifier
	// #############################
	// 'redux.reducers.notifier.NotifyError':{
	// 	en: 'Error!!! {errMsg}',
	// 	zh: '出错啦. {errMsg}',
	// },

	// ##############################
	// utils
	// #############################
	'utils.artwork.savingProgressMsgPreparing': {
		en: `Preparing artwork data...`,
		zh: '',
	},
	'utils.artwork.domainNameNotFoundErrorMsg': {
		en: `Can't find the domain name`,
		zh: '',
	},
	'utils.artwork.savingProgressMsgUploadingFiles': {
		en: `Uploading artwork files...`,
		zh: '',
	},
	'utils.artwork.savingProgressMsgSavingFinalArtwork': {
		en: `Saving final artwork...`,
		zh: '',
	},
	'utils.artwork.unknownCreationModeMsg': {
		en: `Unknown creation mode`,
		zh: '',
	},
	'utils.artwork.savingArtworkMsg': {
		en: 'Saving artwork file...',
		zh: '',
	},
};

export default locales;
