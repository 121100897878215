import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, CircularProgress, TextField, Typography, Popper } from '@mui/material';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

const FONTSIZE = '11px';
const useStyles = makeStyles((theme) => ({
	autoCompleteInputRoot: {
		fontSize: 'inherit',
		padding: '4px !important',
		paddingRight: '40px !important',
	},
	autoCompleteClearIndicator: {
		padding: 0,
		margin: 0,
	},
	autoCompletePopupIndicator: {
		padding: 0,
		margin: 0,
	},
	autoCompleteEndAdornment: {
		top: 'unset',
		right: '0px !important',
	},
	autoCompleteListbox: {
		fontSize: FONTSIZE,
	},
	autoCompleteOption: {
		'&[data-focus="true"]': {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
	},
	popper: {
		width: 'auto',
		minWidth: '280px', // TODO
		// maxWidth: '600px', // TODO
		zIndex: 1300,
	},
}));

// Custom Popper component for Autocomplete to adjust listbox width dynamically
const CustomPopper = (props) => {
	const classes = useStyles();
	return <Popper {...props} className={classes.popper} placement="bottom-start" />;
};

function UserSelector({
	fetchUsers,
	label,
	totalFound,
	onUserSelect,
	hideDefaultList,
	customAPIParams = {},
	initialValue,
	...rest
}) {
	const classes = useStyles();
	const initialFormattedOption = initialValue ? initialValue : null;

	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState(initialFormattedOption?.label || '');
	const [isLoading, setIsLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [selectedOption, setSelectedOption] = useState(initialFormattedOption);
	const [options, setOptions] = useState(() =>
		initialFormattedOption ? [initialFormattedOption] : []
	);
	const getMergedQueryParams = useCallback(
		(inputValue, offset) => ({
			offset,
			limit: customAPIParams.limit || 20,
			keywords: inputValue || '',
			...customAPIParams,
		}),
		[customAPIParams]
	);

	const fetchUsersOnInput = useCallback(
		async (query, initVal) => {
			setIsLoading(true);
			try {
				const { data } = await fetchUsers(query, 0);
				// TODO (if initVal is from API)
				let newOptions = [];
				if (initVal) {
					// If a match is found, create an option for it and set it as the selected option.
					const matchedUser = data.find((user) => user.id === initVal.id);
					// If a match is found, create an option for it and set it as the selected option.
					if (matchedUser) {
						newOptions = [
							{
								value: matchedUser.id,
								label: `${matchedUser.firstname} ${matchedUser.lastname} <${matchedUser.email}>`,
							},
						];
						setSelectedOption(newOptions[0]);
					}
				} else {
					// If no initVal is provided, map the entire data set to options.
					newOptions = data.map((user) => ({
						value: user.id,
						label: `${user.firstname} ${user.lastname} <${user.email}>`,
					}));
				}
				setOptions((prevOptions) => {
					const existingIds = new Set(prevOptions.map((option) => option.value));
					const uniqueNewOptions = newOptions.filter((option) => !existingIds.has(option.value));
					return [...prevOptions, ...uniqueNewOptions];
				});
				// setOffset(data.length);
				setOffset((prevOffset) => prevOffset + data.length);
			} catch (error) {
				console.error('Error fetching users:', error);
			} finally {
				setIsLoading(false);
			}
		},
		[fetchUsers]
	);
	const debouncedFetchUserdata = _.debounce(fetchUsersOnInput, 500);

	const debouncedFetchMoreUsers = useCallback(
		() =>
			_.debounce(async (inputValue, currentOffset) => {
				const queryParams = getMergedQueryParams(inputValue, currentOffset);
				fetchUsersOnInput(queryParams);
			}, 500),
		[fetchUsersOnInput, getMergedQueryParams]
	);

	// Update handleInputChange to allow typing
	const handleInputChange = useCallback(
		(value) => {
			if (hideDefaultList) {
				if (value === '') {
					setInputValue('');
					setOpen(false);
					setOptions([]);
					setSelectedOption(null);
				} else {
					setInputValue(value);
					const queryParams = getMergedQueryParams(value, 0);
					debouncedFetchUserdata(queryParams);
					if (!open) {
						setOpen(true);
					}
				}
			} else {
				setInputValue(value);
				const queryParams = getMergedQueryParams(value, 0);
				debouncedFetchUserdata(queryParams);
				setOpen(true);
			}
		},
		[debouncedFetchUserdata, getMergedQueryParams, hideDefaultList, open]
	);

	const handleScroll = useCallback(
		(e) => {
			const listboxNode = e.currentTarget;
			if (
				listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1 &&
				options.length < totalFound
			) {
				debouncedFetchMoreUsers(inputValue, offset);
			}
		},
		[debouncedFetchMoreUsers, inputValue, offset, options.length, totalFound]
	);

	useEffect(() => {
		if (hideDefaultList && !selectedOption && inputValue === '') {
			setOpen(false);
		}
	}, [selectedOption, inputValue, hideDefaultList]);

	return (
		<Autocomplete
			open={open}
			value={selectedOption}
			onClose={() => setOpen(false)}
			options={options}
			loading={isLoading}
			blurOnSelect
			classes={{
				inputRoot: classes.autoCompleteInputRoot,
				clearIndicator: classes.autoCompleteClearIndicator,
				popupIndicator: classes.autoCompletePopupIndicator,
				endAdornment: classes.autoCompleteEndAdornment,
				listbox: classes.autoCompleteListbox,
				option: classes.autoCompleteOption,
			}}
			getOptionLabel={(option) => option.label || ''}
			isOptionEqualToValue={(option, value) => option.label === value.label}
			onFocus={() => {
				if (!hideDefaultList || inputValue || selectedOption) {
					setOpen(true);
					if (!hideDefaultList) {
						const queryParams = getMergedQueryParams('', 0);
						debouncedFetchUserdata(queryParams);
					}
				}
			}}
			onBlur={() => {
				setOpen(false);
				setInputValue('');
			}}
			onInputChange={(e, value, reason) => {
				if (reason !== 'reset') {
					handleInputChange(value);
				}
			}}
			onChange={(e, selectedOption, reason) => {
				if (reason === 'selectOption' && selectedOption) {
					setSelectedOption(selectedOption);
					onUserSelect(selectedOption);
				} else if (reason === 'clear') {
					setSelectedOption(null);
					onUserSelect(null);
					setInputValue('');
					if (hideDefaultList) {
						setOpen(false);
					}
				}
			}}
			PopperComponent={CustomPopper}
			ListboxProps={{
				onScroll: handleScroll,
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					title={selectedOption ? selectedOption.label : ''}
					fullWidth
					label={label}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			renderOption={(props, option) => (
				<li {...props} key={option.value}>
					<Typography title={option.label} variant="body2" noWrap>
						{option.label}
					</Typography>
				</li>
			)}
			{...rest}
		/>
	);
}

UserSelector.propTypes = {
	fetchUsers: PropTypes.func.isRequired, // Function to fetch users based on input change
	label: PropTypes.string.isRequired, // Label for the input field
	totalFound: PropTypes.number.isRequired, // Total number of records found
	onUserSelect: PropTypes.func.isRequired, // Function after Selecting from List
	hideDefaultList: PropTypes.bool, // To hide the list on click (will appear after something is typed)
	customAPIParams: PropTypes.object,
	initialValue: PropTypes.object, //  {value: string, label: string}
};

export default UserSelector;
