/**
 * As we use React/Redux more and more, we have better understanding of the concept of Redux store:
 * Redux store supposes to be application level data state which can be used cross pages/components
 * Component local-state (by useState()) is the data state used within the component. The performance is better than Redux store in case of changing data
 * With Context and local-state, we can achieve same flexibility like Redux
 */
import config from 'config';
import { format } from 'date-fns';
import reduxStore from 'redux/store';
import { axiosRequest } from 'utils/libHelper';
import { getDomainConfig } from 'utils/appHelper';
import { genRandomStr } from 'utils/generalHelper';

const fileManagerAPIBaseUrl = config.filemanagerAPI.baseUrl;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

/**
	* Get clip image of the given mediafile
	*
	* @param {object} opts. Request options. Format:
	 {
		 mediafileId: 'xxx', // mandatary
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 imgUrl: 'xxxx', // optional. image web url for clipping
			 reclip: false, // optional.
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fetchClipImage = (opts = {}) => {
	const { mediafileId, queryParams = {} } = opts;
	if (!mediafileId) return Promise.reject(new Error('Missing mediafile ID'));
	let reqUrl = `${fileManagerAPIBaseUrl}/mediafiles/${mediafileId}/clips`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Search mediafiles
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 uid: 'xxxx', // optional. Default will use the user id in Redux store (by user logged in). If specified, it overrides the default
			 previewSize: 'small'|'medium'|'large'|'lores'|'original', // optional.
			 offset: 0, // optional. Default 0
			 limit: 20, // optional. Default 20
			 sortBy: "xxx", // optional
			 sortDirection: "asc | desc", // optional
		 },
		 bodyParams: {}, // mandatary
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const searchMediaFiles = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	let reqUrl = `${fileManagerAPIBaseUrl}/mediafiles`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		params: { domain: authState.domainName, uid: authState.userId, ...queryParams },
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Get detail info of a mediafile by ID
	*
	* @param {object} opts. Request options. Format:
	 {
		 mediafileId: 'xxxx', // required. Mediafile ID
		 queryParams: {
			 domainName: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 uid: 'xxxx', // optional. Default will use the uid in Redux store (by user logged in). If specified, it overrides the default
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const getMediafileDetailsById = (opts = {}) => {
	const { queryParams = {}, mediafileId } = opts;
	if (!mediafileId) return Promise.reject(new Error(`Mediafile ID is required`));
	let reqUrl = `${fileManagerAPIBaseUrl}/mediafiles/${mediafileId}`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'GET',
		headers: reqHeader,
		params: { domain: authState.domainName, uid: authState.userId, ...queryParams },
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Get downloadable url of a mediafile
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domainName: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 bucket: 'xxxx', // Required. the s3 bucket name where the file is.
			 filePath: 'xxx/xxx', // Required. the file path in s3 bucket
			 expires: 30*60, // optional. Default 30*60 (30 minutes)
			 contentDisposition: 'xxx', // optional. used in Content-Disposition in the reponse of the downloading url. Default is null
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const getDownloadableUrl = (opts = {}) => {
	const { queryParams = {} } = opts;
	let reqUrl = `${fileManagerAPIBaseUrl}/download`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'GET',
		headers: reqHeader,
		params: { domainName: authState.domainName, ...queryParams },
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* PATCH mediafile
	*
	* @param {object} opts. Request options. Format:
	 {
		 mediafileId: 'xxx', // mandatary
		 queryParams: {
			 domain : 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
		 },
		 bodyParams: {}, // mandatary. Change api spec for details of what properties are supported
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const patchMediafile = (opts = {}) => {
	const { mediafileId, queryParams = {}, bodyParams = {} } = opts;
	if (!mediafileId) return Promise.reject(new Error('Missing mediafile ID'));
	let reqUrl = `${fileManagerAPIBaseUrl}/mediafiles/${mediafileId}`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'PATCH',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
 	* Retrieves the list of category tags that are available in the current domain.
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const getCatagoryTags = () => {
	let reqUrl = `${fileManagerAPIBaseUrl}/tags`;
	// let reqUrl = `https://apigw.visualid.com/filemanager/v1/tags`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'GET',
		headers: reqHeader,
		params: { domain: authState.domainName },
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};
export const userManagerFetchMyFiles = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${fileManagerAPIBaseUrl}/mediafiles/date`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Get cloudfront url of the low res preview of a mediafile
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domainName: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 bucket: 'xxxx', // Required. the s3 bucket name where the file is.
			 filePath: 'xxx/xxx', // Required. the file path in s3 bucket
			 expires: 30*60, // optional. Default 30*60 (30 minutes)
			 contentDisposition: 'xxx', // optional. used in Content-Disposition in the reponse of the downloading url. Default is null
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const getPreviewFileUrl = ({ originalMediafileUrl, size }) => {
	const previewAPIBaseUrl = config.previewApi.baseUrl;
	const previewApiKeyName = config.previewApi.apiKeyName;
	const previewApiKeyValue = config.previewApi.apiKeyValue;
	const previewGetPreviewEP = config.previewApi.getPreviewEP;

	let reqUrl = `${previewAPIBaseUrl}${previewGetPreviewEP}?${previewApiKeyName}=${previewApiKeyValue}&redirect=false&fileurl=${originalMediafileUrl}&size=${size}`;

	let reqHeader = {
		'Content-Type': 'application/json',
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'GET',
		headers: reqHeader,
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
 	* Delete file from mediafile by file ID in the current domain.
	*
	* @param {object} opts. Request options. Format:
	 {
		 mediafileId: 'xxx', // mandatary
		 fileId: 'xxx', // mandatary
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const deleteFileFromMediafileByFileId = (opts = {}) => {
	const { mediafileId, fileId, queryParams } = opts;
	let reqUrl = `${fileManagerAPIBaseUrl}/mediafiles/${mediafileId}/files/${fileId}`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'DELETE',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Fetch prices (pricing categories)
 * @param {object} opts
 * @returns
 */
export const fetchPrices = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${fileManagerAPIBaseUrl}/pricing/`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * // Fetch metadatas
 * @param {object} opts
 * @returns
 */
export const fetchMetaDatas = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${fileManagerAPIBaseUrl}/metadata`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * upload files to s3
 * @param {object} opts
	{
		 s3Client: 'xxx', // mandatary
		 files: [], // mandatary. A list "file" object
		 s3BasePath: '', // optional. Must NOT have slash at beginning; Must have slash at end, e.g. spar/2o23/sdfds/
	 }.
 * @returns files
	 [
		{
			...file, failedReason: '', s3Url: ''
		}
	 ]
 */
export const uploadFilesToS3 = async (opts = {}) => {
	const { s3Client, files, s3BasePath } = opts;

	const authState = reduxStore.getState().authentication;
	const domainConf = getDomainConfig(authState.domainName);
	const s3FilepathBase =
		s3BasePath || `${authState.domainName}/${format(new Date(), 'yyyyMMdd')}/${genRandomStr(28)}/`;

	const uploadedResults = await Promise.allSettled(
		files.map((f) => {
			let s3Filename = `${genRandomStr(12)}.${f.name.split('.').pop()}`;
			let s3FileParams = {
				Bucket: domainConf.s3Bucket,
				Key: s3FilepathBase + s3Filename,
				Body: f,
				ContentType: f.type,
				ContentLength: f.size,
			};
			let options = {
				partSize: 40 * 1024 * 1024,
				queueSize: 4,
			};
			return s3Client.upload(s3FileParams, options).promise();
		})
	);

	return uploadedResults.map((result, idx) => {
		const file = files[idx];
		if (result.status === 'rejected') {
			file.failedReason = result.reason;
		} else {
			file.s3Url = `s3://${result.value.Bucket}/${result.value.Key}`;
		}
		return file;
	});
};

/**
 * replace file for a mediafile
 * @param {object} opts
	{
		file: file, // mandatory
		mediafileId: '', // madatory
		userId: '', // optional, default to login user id
	}
 * @returns
 */
export const replaceFile = (opts = {}) => {
	const { file, userId, mediafileId } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${fileManagerAPIBaseUrl}/replace/`;
	const bodyParams = {
		file: {
			url: file.s3Url,
			originalName: file.name,
			size: file.size,
		},
		dbName: authState.domainName,
		domainName: authState.domainName,
		uploadDatetime: new Date().toISOString(),
		userID: userId || authState.userId,
		mediafileID: mediafileId,
		domainUrl: window.location.protocol + '//' + window.location.host,
	};

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Add extra file for a mediafile
 * @param {object} opts
	{
		file: file, // mandatory
		mediafileId: '', // madatory
		userId: '', // optional, default to login user id
	}
 * @returns
 */
export const addExtraFile = (opts = {}) => {
	const { file, userId, mediafileId } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${fileManagerAPIBaseUrl}/extra/`;
	const bodyParams = {
		file: {
			url: file.s3Url,
			originalName: file.name,
			size: file.size,
		},
		dbName: authState.domainName,
		domainName: authState.domainName,
		uploadDatetime: new Date().toISOString(),
		userID: userId || authState.userId,
		mediafileID: mediafileId,
	};

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * // Get rgb color from cmyk
 * @param {object} opts
 * @returns
 */
export const getRgbFromCmyk = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${fileManagerAPIBaseUrl}/cmyktorgb`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

export const uploadFileFromAI = (opts = {}) => {
	const { bodyParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${fileManagerAPIBaseUrl}/upload/`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

export const fetchSpreadsheetDetailsById = (opts = {}) => {
	let { spreadsheetId, queryParams = {} } = opts;
	if (!spreadsheetId) throw new Error('Missing spreadsheet ID');
	let reqUrl = `${fileManagerAPIBaseUrl}/spreadsheets/${spreadsheetId}/rowselect`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

export const fetchSpreadsheetContentById = (opts = {}) => {
	let { spreadsheetId, queryParams = {} } = opts;
	if (!spreadsheetId) throw new Error('Missing spreadsheet ID');
	let reqUrl = `${fileManagerAPIBaseUrl}/spreadsheets/${spreadsheetId}`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

// *********************** NEW API *************
export const fetchLightBoxList = (opts = {}) => {
	const { queryParams = {}, lightboxId } = opts;
	if (!lightboxId) return Promise.reject(new Error(`Lightbox ID is required`));
	let reqUrl = `${fileManagerAPIBaseUrl}/lightboxes/${lightboxId}`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'GET',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

export const postLightBoxFiles = (opts = {}) => {
	const { lightboxId, queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${fileManagerAPIBaseUrl}/lightboxes/${lightboxId}/files`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 180000,
	};

	return axiosRequest(axiosConfig);
};
export const deleteLightBoxFiles = (opts = {}) => {
	const { lightboxId, queryParams } = opts;
	let reqUrl = `${fileManagerAPIBaseUrl}/lightboxes/${lightboxId}/files`;
	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'DELETE',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};
/**
 * Following are a list of exported functions
 */
// const defaultExport = {
// 	fetchClipImage, // Get clip image of the given mediafile
// 	searchMediaFiles, // Search mediafiles
// 	getDownloadableUrl, // Get downloadable url of a mediafile
// 	patchMediafile, // PATCH mediafile
// 	getMediafileDetailsById, // Get mediafile detail by mediafile id
// 	getCatagoryTags, // Get category tags in search field
// 	deleteFileFromMediafileByFileId, // Delete file from mediafile by file ID in the current domain
// 	fetchPrices, // Fetch prices (pricing categories)
// 	fetchMetaDatas, // Fetch metadatas
//	uploadFilesToS3, // upload files to s3
//	replaceFile,	// replace file for a mediafile
//	addExtraFile, // Add extra file for a mediafile
//	getRgbFromCmyk, // Get rgb color from cmyk
// };
